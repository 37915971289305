.companyCard {
  border-radius: 8px;
  border: 1px solid var(--e-4-e-6-e-8, #e4e6e8);
  background: #fff;
}

.searchCompanyInput:focus {
  border-bottom-color: transparent;
  outline: none;
}
.noresult {
  color: var(--171721-primary, #171721);
  font-feature-settings: "clig" off, "liga" off;
  /* Heading / H5 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}
.cardbutton {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: var(--Gray-600, #475467);
}

.selectbutton {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  width: 98px;
  height: 44px;
}

.scrollbaredit::-webkit-scrollbar {
  width: 5px;

  border-radius: 8px;
  /* Set the width of the scrollbar */
}

.scrollbaredit::-webkit-scrollbar-thumb {
  background-color: #e31b54; /* Set the color of the scrollbar thumb */
}

.scrollbaredit::-webkit-scrollbar-track {
  background-color: #fff; /* Set the color of the scrollbar track */
}
@media screen and (width<= 652px) {
  .innercard {
    width: 90vw; /* Adjust the vw unit as needed */
  }
  .noresult {
    font-size: 14px;
  }
}

.searchicon {
  display: flex;
  width: 56px;
  height: 56px;
  padding: 13.5px 14px 14.5px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.graybarekit::-webkit-scrollbar {
  width: 5px;

  /* Set the width of the scrollbar */
}

.graybarekit::-webkit-scrollbar-thumb {
  background-color: #d0d5dd;
  border-radius: 10px; /* Set the color of the scrollbar thumb */
}

.graybarekit::-webkit-scrollbar-track {
  background-color: #fff; /* Set the color of the scrollbar track */
}

.cardcompanystore {
  border-radius: 8px;
  /* border: 1px solid var(--e-4-e-6-e-8, #e4e6e8); */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.companyTitle {
  color: var(--171721-primary, #171721);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.companyLocation {
  margin-top: 4px;
  color: #344054;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
}
.welcome {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.cantfind {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.cantfind span {
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}
.backtologin {
  margin-top: 2rem;
  color: var(--Gray-600, #475467);

  /* Text sm/Semibold */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.byname {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
}
.gray70020px {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
}

.popupouter {
  min-width: 300px;
  padding: 32px 16px 16px 16px;

  border-radius: 20px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  background: rgba(255, 255, 255, 0.8);
}
.popupinner {
  padding: 40px 24px 24px 24px;

  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
}
.CompanyDashboardChallengeRight {
  width: 324px;

  flex-shrink: 0;

  padding: 1rem;
  border-radius: 5px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  background: rgba(255, 255, 255, 1);
}
.Challengescardouterwhitediv {
  max-width: 250px;
  width: 250px;
  height: 281px;
  padding: 0.5rem;
  border-radius: 5px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  background: rgba(255, 255, 255, 0.4);
  transition: all 0.3 ease-in-out;
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
  transition: all 0.3s ease-in-out;
}
.Challengescardouterwhitediv:hover {
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.Challengescardinnergreydiv {
  width: 233.358px;
  height: 225px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Secondary-25, #fff);
}
.challengescardinsidegreybackgroundimagediv {
  width: 233.358px;
  height: 125px;
  flex-shrink: 0;
  border-radius: 8px 8px 0px 0px;
}
.PreviewCompanyDashboardButton {
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 5px;
  background: var(--Primary-400, #fd6f8e);
  color: var(--Snow, #fff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
}

.PreviewCompanyDashboardButton:hover {
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
  background: #e31b54;
}
.challengescardinsidegreybackgroundimagedivcompanycard {
  position: absolute;
  margin-top: 8px;
  margin-left: 8px;
}
.ChallengecardChallengeTitle {
  color: var(--171721-primary, #171721);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  /* word-break: break-all; */
  width: 218px;
}
.ChallengeCardCompanyName {
  color: #e13551;
  font-family: Poppins;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 14.5px */
  width: 200.345px;
}
.ChallengecardTaskCounter {
  color: #98a2b3;
  text-align: center;
  font-family: Poppins;
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 12.823px; /* 160.282% */
}
.addChallengeDiv {
  display: flex;
  width: 250px;
  height: 281px;
  padding: 0px 113px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  border-top: 1.5px solid var(--Base-White, #fff);
  border-left: 1.5px solid var(--Base-White, #fff);
  background: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.addChallengeDiv:hover {
  border-top: 2px solid var(--Base-White, #fff);
  border-left: 2px solid var(--Base-White, #fff);
  background: var(--Primary-25, #fff5f6);

  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);

  /* Trans Card v2 */
}

.addChallengeDiv:hover .svg path {
  fill: #e31b54;
}
.contactusbutton {
  display: flex;
  height: 2.5rem;
  padding: 0.625rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.375rem;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: #475467;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 171.429% */
}
.feelfree {
  color: #344054;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.letsStartTogether {
  color: #0c111d;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}
.bluerightsidedivs {
  display: flex;
  padding: 6px 14.993px;
  align-items: center;
  gap: 11.995px;
  border-radius: 30px;
  border: 0.75px solid #eff0f1;
  background: linear-gradient(
      0deg,
      var(--Secondary-25, #f5f8ff) 0%,
      var(--Secondary-25, #f5f8ff) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: #0c111d;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
}
.learnmoretext {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 125% */
}
.belowLearnMoreText {
  color: #0c111d;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
}
.belowLearnMoreText span {
  color: #0c111d;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
}
.newStudentPopupChips {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  border: 1px solid var(--Secondary-Color-Gray-300, #d0d5dd);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.newStudentPopupChips:hover {
  border: 1px solid var(--Secondary-Color-Gray-300, #d0d5dd);
  background: linear-gradient(
      0deg,
      var(--Secondary-Color-Gray-25, #fcfcfd) 0%,
      var(--Secondary-Color-Gray-25, #fcfcfd) 100%
    ),
    rgba(255, 255, 255, 0.2);

  /* Shadow/Blue Shadow */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.newdashboardsidechips {
  display: flex;
  height: 32px;
  padding: 6px 14px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid var(--Gray-200, #d0d5dd);
  background: linear-gradient(
      0deg,
      var(--Base-White, #fff) 0%,
      var(--Base-White, #fff) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.ChallengesCardNew-Outer {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  transition: all 0.3s ease;
  overflow: hidden; /* Ensures content doesn't overflow */
  position: relative;
}
.ChallengesCardNew-Outer-Student {
  width: 100%;

  transition: all 0.3s ease;
  overflow: hidden; /* Ensures content doesn't overflow */
  position: relative;
}
.gray40012400 {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.gray40016500 {
  color: var(--Secondary-Color-Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.gray75010500 {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
}
.ChallengesCardNew-Inner {
  background: #fff;
  min-height: 226px;
  max-height: 226px;
  border-radius: 0px 0px 5px 5px;
  padding: 1rem;
  transition: transform 0.3s ease-in-out;
}
.ChallengenewCarddescription {
  max-height: 0;
  overflow: hidden;
  /* display: none; */
  opacity: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    margin-top 0.3s ease-in-out; /* Smooth transition for height and opacity */
}
@media (max-width: 1024px) {
  .ChallengenewCarddescription {
    display: block;
    margin-top: 0.5rem;
    max-height: 70px; /* Adjust this value to control the maximum height */
    opacity: 1;
  }
}

.ChallengesCardNew-Outer:hover .ChallengenewCarddescription {
  display: block;
  margin-top: 0.5rem;
  max-height: 70px; /* Adjust this value to control the maximum height */
  opacity: 1; /* Makes the description fully visible */
}
.ChallengesCardNew-Outer-Student:hover .ChallengenewCarddescription {
  display: block;
  margin-top: 0.5rem;
  max-height: 70px; /* Adjust this value to control the maximum height */
  opacity: 1; /* Makes the description fully visible */
}

/* .ChallengesCardNew-Outer:hover .ChallengesCardNew-Inner {
  transform: translateY(
    -20px
  );
} */

.country-icon {
  width: 20px; /* Adjust the width as needed */
  height: auto; /* Maintain the aspect ratio */
  margin-right: 5px; /* Optional spacing between icon and text */
}
.select-without-border {
  border: none;
  outline: none;
}
.tagscard {
  display: flex;
  padding: 4px 6px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: var(--Primary-25, #ffffff);
}

.selectedcard {
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: linear-gradient(
      0deg,
      var(--Primary-300, #fea3b4) 0%,
      var(--Primary-300, #fea3b4) 100%
    ),
    rgba(255, 255, 255, 0.2);
}
.countrylist {
  margin-top: 10px;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  font-family: Poppins;
  font-size: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.scrollbaredit::-webkit-scrollbar {
  width: 5px;

  /* Set the width of the scrollbar */
}

.scrollbaredit::-webkit-scrollbar-thumb {
  background-color: #e31b54; /* Set the color of the scrollbar thumb */
}

.scrollbaredit::-webkit-scrollbar-track {
  background-color: #fff; /* Set the color of the scrollbar track */
}
.sidebarcompanydashboardmove {
  position: fixed;

  z-index: 100;
  transition: all 0.3s ease-in-out;
}
.sidebarcompanydashboardmove.actived {
  top: 0;
  left: 0;
}
.sidebarcompanydashboardmove.inactived {
  top: 0;
  left: -250%;
}
.userimage {
  border-radius: 180px;
}
.CompanydashboardsideBar {
  min-height: 100vh;
  /* height: 100%; */
  min-width: 18.75rem;
  max-width: 18.76rem;

  background: #fff;
}
.paddingLeftTESting {
  padding-left: 18.76rem;
}
.CompanydashboardsideBarButton {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  color: #101828;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}
.CompanydashboardsideBarButton:hover {
  color: #e31b54;
}
.CompanydashboardsideBarButton:hover svg path {
  fill: #e31b54 !important;
}
.CompanyDashboardSidebarToolstext {
  color: var(--Gray-400, #98a2b3);
  font-family: "Poppins";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: 0.033rem;
  text-transform: uppercase;
}
.CompanyDashboardSidebarInside {
  margin-top: 1.875rem;
  margin-left: 1.25rem;

  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 1440px) {
  .CompanydashboardsideBar {
    min-width: 15rem;
    max-width: 15.1rem;
  }
  .paddingLeftTESting {
    padding-left: 15rem;
  }
  .CompanyDashboardSidebarInside {
    margin-top: 1.875rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 1.25rem;
  }
}

.userName {
  color: var(--Gray-950, #0c111d);

  font-size: 30px;
  font-weight: 400;
}

.compantText {
  color: var(--Gray-700, #344054);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}
.admintext {
  color: var(--Gray-400, var(--Gray-400, #98a2b3));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.507px;
  text-transform: uppercase;
}
.bottomdiv {
  display: flex;

  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: var(--Secondary-25, #f9fafb);
}
.bottomdivfirst {
  color: var(--Gray-900, #101828);

  /* Text sm/Semibold */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 142.857% */
}
.bottomdivsecond {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.bottomfirstbutton {
  color: var(--Gray-600, #475467);
  /* Text sm/Semibold */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.bottomsecondbutton {
  color: var(--Primary-600, #e31b54);
  /* Text sm/Semibold */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

@media screen and (max-width: 1199px) {
  .CompanydashboardsideBar {
    min-width: 5rem;
    max-width: 5.1rem;
  }
  .paddingLeftTESting {
    padding-left: 5rem;
  }
}

@media screen and (max-width: 1023px) {
  .CompanydashboardsideBar {
    min-width: 15rem;
    max-width: 15.1rem;
  }
  .paddingLeftTESting {
    padding-left: 1.5rem;
  }
}

.university-first {
  position: relative;
  width: 100vw;
  margin-top: 65px;
}
.university-first * {
  overflow: visible;
}

.hellocareer {
  display: flex;
  padding: 10px 20px;

  align-items: flex-start;
  gap: 10px;

  border-radius: 60px;
  border: 1px solid var(--Secondary-Color-Gray-300, #d0d5dd);
  background: rgba(249, 250, 251, 0.3);
  color: var(--Secondary-Color-Gray-950, #0c111d);

  /* Text Styles/Body Text/text-sm-medium */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.circle-background-large {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-background-large::before {
  content: "";
  position: absolute;
  width: 90%;
  height: auto;
  aspect-ratio: 1/1;
  z-index: 11;
  border-radius: 50%;
  border: 1px dashed var(--Secondary-Color-Gray-300, #d0d5dd);
}

.circle-background-large::after {
  content: "";
  position: absolute;
  z-index: 11;
  width: 100%; /* Adjust inner circle size */
  height: auto;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 1px dashed var(--Secondary-Color-Gray-300, #d0d5dd);
}

.circle-background-large .circle-container {
  position: absolute;
}

.circle-background-large .circle-container::before {
  content: "";
  position: absolute;
  width: 150%;
  height: 400%;
  z-index: 10;
  border-radius: 50%;
  /* border: 1px dashed var(--Secondary-Color-Gray-300, #d0d5dd); */
}

.circle-background-large .circle-container::after {
  content: "";
  position: absolute;
  width: 90%;
  height: 250%;
  z-index: 12;
  border-radius: 50%;
  /* border: 1px dashed var(--Secondary-Color-Gray-300, #d0d5dd);  */
}
.stepschips {
  display: flex;

  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 30px;
  background: var(--Secondary-50, #eef4ff);
  color: var(--Secondary-700, #3538cd);
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  width: 86px;
  height: 28px;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
}
/* @media (max-width: 768px) {
  .circle-background-large::before {
    width: 180%;
  }
  .circle-background-large::after {
    width: 150%;
  }
} */

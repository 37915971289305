.RegisterOrganizationHeader {
  margin-top: 40px;
}
.marginsouter {
  margin-top: 6rem;
  margin-left: 9rem;
}
.margin56 {
  margin-top: 3.5rem;
}

.underregisteration {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.signup {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: clamp(0.75rem, 4vw, 1rem);
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 150% */
  margin-top: 1rem;
}
.Email {
  color: #030303;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.orbetween {
  color: #0c111d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.registerButton {
  display: flex;

  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  border: none;
  background: var(--Primary-600, #e31b54);
  color: var(--Snow, #fff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  margin-top: 2rem;
}
.registerButton:hover {
  background: var(--Primary-700, #c01048);

  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.registerButton:disabled {
  border-radius: 6px;
  background: var(--Primary-300, #fea3b4);
}

.newSupportButton {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}
.newSupportButton:hover {
  color: #e31b54;
}

.linkedinButton {
  display: flex;

  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: #0c111d;
  gap: 0.5rem;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.linkedinButton:hover {
  background: #fcfcfd;

  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.already {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: clamp(0.75rem, 4vw, 1rem);
  font-style: normal;
  font-weight: 400;
  line-height: 26.667px; /* 142.857% */
  position: relative;
  z-index: 5;
}
.login {
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: clamp(0.75rem, 4vw, 1rem);
  font-style: normal;
  font-weight: 600;
  line-height: 26.667px; /* 142.857% */
  cursor: pointer;
}
.nddiv {
  margin-left: 12rem;
  margin-right: 6rem;
  margin-bottom: 2rem;
  margin-top: 3rem;
  background: url(https://varyance.b-cdn.net/uploads/nddiv.png);
  background-repeat: no-repeat;
  background-size: 100% 100%; /* Cover the entire container */
  background-position: top;
  max-height: 80%; /* Set the height to a fixed value or a percentage */

  @media (max-width: 768px) {
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 2rem;
    height: 80vh;
  }
}
@media only screen and (max-width: 768px) {
  .marginsouter {
    margin-top: 3rem;
    margin-left: 1rem;
  }
}

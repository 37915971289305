#cookiescript_injected * {
  font-family: "Poppins", sans-serif;
}
:root {
  --primaryColor: #e31b54;
  --primaryLightColor: #e7396a;
  --primaryDarkColor: #c51647;
  --secondaryColor: #ffffff;
  /* fonts sizes / */
  /* hero */
  --heroFont: 40px;
  --subPageHeroFont: 35px;
  /* main  */
  --mainHeading: 36px;
  --subHeading: 24px;
  /* secondary  */
  --secHeading: 32px;
  --subSecHeading: 20px;
  /* body */
  --bodyMainFontSize: 18px;
  --bodySecondaryFontSize: 16px;
}
.compared {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
  opacity: 0.6;
}

.goodjob {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
  opacity: 0.6;
}
.skillname {
  color: var(--Night-Blue, #383874);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.4px;
}
.skillsshow:hover .skillsshowtext {
  color: #e31b54;
}
.upcoming-outer {
  padding: 20px 18px 46px 20px;

  align-self: stretch;

  border-radius: 5px;
  background: #fff;

  /* blue */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.upcoming-inner {
  padding: 20px;

  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
  margin-top: 1.25rem;
}
.upcoming-inner:hover {
  border-radius: 5px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Gray-50, #f9fafb);
  cursor: pointer;
}
@media (max-width: 768px) {
  .upcoming-inner {
    padding: 1rem;
  }
}
.upcomingDay {
  color: var(--Gray-600, #475467);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 160% */
}
.upcomingDate {
  color: var(--Gray-950, #0c111d);
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.upcoming-type-outer {
  display: flex;
  padding: 6px 10px;
  align-items: center;
  gap: 8px;
  border-radius: 30px;

  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.upcoming-host {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.upcoming-host span {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.EventsTypeContainer {
  display: flex;
  max-height: 33.6px;
  padding: 8.4px 14px;
  align-items: center;
  gap: 11.2px;
  border-radius: 42px;
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
}
.newtr {
  background: var(--Gray-50, #f9fafb);
}
.newtr th {
  padding: 8px 16px;
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.trnew {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 1rem;
  padding-bottom: 10px;
  color: var(--Gray-800, #1d2939);
  font-feature-settings: "liga" off, "clig" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.bottomborder {
  border-bottom: 1px solid #eaecf0;
  cursor: pointer;
}
.studentsupport input,
textarea {
  box-shadow: none !important;
}
.bluebutton {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 20px;
  background: var(--Gray-50, #f9fafb);
  color: #667085;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px; /* 116.667% */
  border: none;
  transition: all 0.2s ease-in-out;
}
.bluebutton:hover {
  background: var(--Gray-50, #e31b54);
  color: #fff;
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.ChipCover-magazine {
  display: flex;
  height: 36px;
  padding: 10px;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  border-top: 2px solid var(--White, #fff);
  border-left: 2px solid var(--White, #fff);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: var(--Secondary-Color-Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.bottomborder:hover .trnew {
  color: #e31b54;
  font-weight: 500;
}
.group-card-container:hover .gray95016500 {
  color: #e31b54;
}
.marginonthis {
  margin-bottom: -15.5rem;
  transition: all 0.3s ease-in-out;
}
.red {
  color: #1a1a1a;
}
.hoveronthis {
  transition: color 0.3s ease-in-out;
}
.hoveronthis:hover .red {
  color: #e31b54;
}
.hoveronthisone:hover .marginonthis {
  margin-bottom: 0rem;
}
.hovergroupchips {
  cursor: pointer;
}
.hovergroupchips:hover {
  background: #f2f3f7;
}
.upcoming-speaker-img {
  border-radius: 30px;
  border: 2px solid #fff;
  background: lightgray 50% / cover no-repeat;
}
.selectedEvent-inner {
  padding: 16px;

  gap: 20px;
  border-radius: 5px;
  background: #fff;
}
.selectedview-questions-inner {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Gray-50, #f9fafb);
}

/* Base styles for the button */
.continuebutton {
  background-color: #007bff; /* Button color */
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  position: relative; /* Necessary for the pulse positioning */
  z-index: 1; /* Keeps button above the pulse */
  outline: none;
}

.skillsshow {
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  border-radius: 180px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  transition: all 0.3 ease-in-out;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.skillsshow:hover {
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.skillsshowtext {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  transition: color 0.3s ease-in-out;
}
.scoregot {
  color: var(--Night-Blue, var(--Gray-700, #344054));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  opacity: 0.6;
}
.expanded {
  color: var(--Night-Blue, #344054);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.44px;
}
.divider {
  height: 1px;
  width: 100%;
  background: #eaecf0;
}
.loader-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 767px) {
  /* fonts sizes / */
  :root {
    /* hero */
    --heroFont: 30px;
    --subPageHeroFont: 26px;
    /* main */
    --mainHeading: 30px;
    --subHeading: 22px;
    /* secondary  */
    --secHeading: 28px;
    --subSecHeading: 18px;
    /* body */
    --bodyMainFontSize: 16px;
    --bodySecondaryFontSize: 16px;
  }
}
body,
* {
  font-family: "FilmotypeFashion", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  /* scroll-behavior: smooth; */
}

p {
  margin: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

/* scroll bar  */
/* width */
/* \ */
/* scroll bar end */
.primary_btn {
  border: none;
  background-color: var(--primaryColor);
  color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  font-family: "Poppins";
  /* font-size: 16; */
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: 700;
}
.white16 {
  color: var(--Base-White, #fff);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}
.white12400 {
  color: var(--White, #fff);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 150% */
}
.hidescroll {
  max-width: 100%;
  overflow-x: auto;
  scrollbar-width: none; /* For Firefox */
}

.hidescroll::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.gray50012500 {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
}
.gray80024400 {
  color: var(--Secondary-Color-Gray-800, #1d2939);

  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}
.didnt {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.didnt span {
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}
.joblivestatusouter {
  display: inline-flex;
  padding: 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}
.askfnalskfna .slick-slide {
  /* width: 318px !important; */
  padding: 2rem 0rem;
}
.askfnalskfna .slick-list {
  max-height: 250px !important;
}

.askfnalskfna .slick-list {
  padding: 0rem !important;

  min-width: 820px;
  height: 400px;
}
.newPopUpContainer {
  border-radius: 12px;
  background: #fff;
  padding: 1.5rem;
  z-index: 9999;
  position: relative;
}
.difficultybuttons {
  display: flex;

  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;

  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.whitechip {
  border-radius: 30px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 100%
    ),
    var(--Base-White, #fff);
  display: flex;
  padding: 8px 14px;
  align-items: center;
  gap: 6px;
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.ContactOuter {
  background: url(https://varyance.b-cdn.net/uploads/rolebackground.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  width: 100vw;
}

.difficultybuttons:hover {
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.difficultybuttons:disabled {
  opacity: 0.3;
}
.primary_btn:hover {
  background-color: var(--primaryDarkColor);
  box-shadow: 0 0 10px rgb(213, 208, 237);
}

/* hide the scroll bar */
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

.container {
  max-width: 1444px;
}
.footerhover {
  transition: color 0.3s ease-in-out;
}
.footerhover:hover {
  color: #98a2b3;
}
.nonmobile14 {
  font-size: clamp(14px, 3vw, 16px) !important;
  line-height: clamp(20px, 3vw, 24px) !important;
}

.logoimage {
  width: 2.25rem;
  height: 2.25rem;
  filter: drop-shadow(0px 0.23px 0.184px rgba(0, 49, 122, 0.02))
    drop-shadow(0px 0.552px 0.442px rgba(0, 49, 122, 0.03))
    drop-shadow(0px 1.04px 0.832px rgba(0, 49, 122, 0.04))
    drop-shadow(0px 1.856px 1.485px rgba(0, 49, 122, 0.04))
    drop-shadow(0px 3.471px 2.777px rgba(0, 49, 122, 0.05))
    drop-shadow(0px 8.308px 6.646px rgba(0, 49, 122, 0.07));
}
.headerCompany {
  width: 73.125rem;
}
.headerCompanytest {
  min-width: 73.125rem;
}
.createcompany {
  width: 100%;
  min-height: 100vh;
  background: url(https://varyance.b-cdn.net/uploads/newregisterbackground.png)
    no-repeat center center;
  background-size: cover;
}

.newSearchInput {
  display: flex;
  /* width: 180px; */
  padding: 4px;
  /* justify-content: center; */
  align-items: center;
  gap: 8px;
  border: none;
  border-bottom: 1px solid var(--Secondary-Color-Gray-500, #667085);
}

@media (max-width: 500px) {
  .newSearchInput,
  .newSearchInner {
    width: 100%;
  }
}

.studentHeading {
  color: var(--Secondary-Color-Gray-900, #101828);

  font-size: clamp(1.25rem, 4vw, 1.5rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(1.5rem, 4vw, 2rem); /* 133.333% */
}
.newSearchInner {
  display: flex;
  padding: 8px 12px;
  /* justify-content: center; */
  align-items: center;
  gap: 8px;
  flex: 1 0 auto;
  border-radius: 6px;
}
.newSearchInputInner {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */

  background: transparent;
  border: none;
  outline: none;
}

.newSearchInputInner::placeholder {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.Student-dashboard-filter-button {
  display: flex;
  align-items: center;
  gap: 12px;
  color: var(--Secondary-Color-Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%; /* 18.9px */
  background: none;
  border: none;
  transition: color 0.2s ease-in-out;
}
.Student-dashboard-filter-button:hover {
  color: #e31b54;
}
.studentFiltersOverlay {
  position: fixed;
  left: 200%;
  height: 100vh;
  display: flex;
  justify-content: end;
  width: 100vw;
  z-index: 1100;
  top: 0;
  background: transparent;
  backdrop-filter: blur(3px);
  transition: all 0.3s ease-in-out, background 1s ease-in;
}
.studentFiltersOverlay.turnedon {
  left: 0;
  background: rgba(45, 50, 130, 0.3);
}
.Student-dashboard-innerFilter {
  width: 380px;
  max-width: 380px;
  min-width: 380px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-top: 2px solid #fff;

  border-left: 2px solid #fff;
}
@media screen and (max-width: 380px) {
  .Student-dashboard-innerFilter {
    width: 100vw;
    min-width: 100vw;
    max-width: 100vw;
  }
}

@media screen and (max-width: 1270px) {
  .headerCompany {
    width: 100%;
  }
  .headerCompanytest {
    min-width: 100%;
  }
}

.continuebutton {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  /* border: 1px solid var(--Gray-300, #d0d5dd); */
  border: none;
  background: var(--Primary-600, #e31b54);
  color: var(--Snow, #fff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.continuebuttonsmaller {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Primary-600, #e31b54);
  color: var(--Snow, #fff);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.continuebuttonsmaller:hover {
  background: var(--Primary-700, #c01048);

  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}

.continuebuttonsmaller:disabled {
  border-radius: 6px;
  background: var(--Primary-300, #fea3b4);
}

.continuebutton:hover {
  background: var(--Primary-700, #c01048);

  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}

.continuebutton:disabled {
  border-radius: 6px;
  background: var(--Primary-300, #f0cad1);
}

.formtext {
  color: #344054;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.tagsareused {
  color: #e31b54;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.forminput {
  display: flex;
  padding: 12px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  transition: all 0.3s ease-in-out;
}
.gray40014500 {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.forminput::placeholder {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.forminput:hover {
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.forminput:focus {
  outline: none;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.forminput.success {
  border-radius: 8px;
  border: 1px solid var(--Success-600, #079455);
  background: var(--Base-White, #fff);
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.forminput.error {
  border-radius: 8px;
  border: 1px solid var(--Error-400, #f97066);
  background: var(--Base-White, #fff);
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.options {
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.drop-area {
  display: flex;

  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  text-align: center;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
}

.drop-area img {
  margin-top: 10px;
}

.tag-container {
  gap: 10px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
}

.tags {
  display: flex;

  padding: 9px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.selected {
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: linear-gradient(
      0deg,
      var(--Primary-300, #fea3b4) 0%,
      var(--Primary-300, #fea3b4) 100%
    ),
    rgba(255, 255, 255, 0.2);
}
.tags img {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.itemstexts {
  color: var(--gray-500-p, var(--Gray-500, #667085));
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.checkbox {
  width: 18px;
  height: 18px;
}

.backbutton {
  display: inline-flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: var(--Gray-600, #475467);
  color: #475467;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.newjoinbuttonhover {
  display: inline-flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  /* border: 2px solid var(--Gray-300, #aaadb3); */
  border: none;
  background: #fff;
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.newjoinbuttonhover:hover {
  color: #e31b54;
  border: 0.75px solid var(--Gray-300, #d0d5dd);

  box-shadow: 0px 0.75px 1.5px 0px rgba(16, 24, 40, 0.05);
}
.backbutton:hover {
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.continuebutton {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  /* border: 1px solid var(--Gray-300, #d0d5dd); */
  border: none;
  background: var(--Primary-600, #e31b54);
  color: var(--Snow, #fff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.continuebuttonsmaller {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Primary-600, #e31b54);
  color: var(--Snow, #fff);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.continuebuttonsmaller:hover {
  background: var(--Primary-700, #c01048);

  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}

.continuebuttonsmaller:disabled {
  border-radius: 6px;
  background: var(--Primary-300, #fea3b4);
}

.continuebutton:hover {
  background: var(--Primary-700, #c01048);

  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.continuebutton:disabled {
  border-radius: 6px;
  background: var(--Primary-300, #f0cad1);
}
.companyCardtoDisplay {
  width: 80%;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  position: sticky;
  top: 20%;
}
.custom-image {
  position: relative;

  max-width: 78px;
  height: 78px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-radius: 5px;
  transform: rotate(-0.219896deg);
  filter: drop-shadow(2.94359px 2.82954px 1.17744px rgba(0, 0, 0, 0.07))
    drop-shadow(7.07386px 5.32778px 2.82954px rgba(0, 0, 0, 0.035))
    drop-shadow(13.3194px 9.50385px 5.32778px rgba(0, 0, 0, 0.0282725))
    drop-shadow(23.7596px 17.7759px 9.50385px rgba(0, 0, 0, 0.0196802))
    drop-shadow(44.4398px 42.5489px 17.7759px rgba(0, 0, 0, 0.01));
}

.companyname {
  color: var(--Gray-900, #101828);

  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.tagline {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.CreateProfileCompanyName {
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  max-width: 90%;
}

.tagscard {
  display: flex;
  padding: 4px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: var(--Primary-25, #fea3b4);
}
.selectedcard {
  color: var(--gray-500-p, var(--Gray-500, #667085));
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.theadtrth {
  width: 248px;
  padding: 12px 16px;

  gap: 12px;
  border-radius: 5px 0px 0px 0px;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Gray-50, #f9fafb);
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.gray70014400 {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.gray70012500 {
  color: var(--Secondary-Color-Gray-700, #344054);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.gray90018500 {
  color: var(--Gray-900, #101828);

  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}
.gray90020400 {
  color: var(--Gray-900, #101828);

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
}
.gray90014400 {
  color: var(--Secondary-Color-Gray-900, #101828);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.circle-background {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-background::before {
  content: "";
  position: absolute;
  width: 80px; /* Adjust circle size */
  height: 80px;
  z-index: 11;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1); /* Outer circle */
  animation: pulse 1.5s infinite;
}

.circle-background::after {
  content: "";
  position: absolute;
  z-index: 11;
  width: 70px; /* Adjust inner circle size */
  height: 70px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1); /* Inner circle */
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}
.Registered {
  display: flex;
  max-width: 85px;
  padding: 5px 8px 5px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid var(--Success-200, #abefc6);
  background: var(--Success-50, #ecfdf3);
  mix-blend-mode: multiply;
  color: var(--Success-700, #067647);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}

.Pending {
  display: flex;
  width: 85.695px;
  padding: 5px 8px 5px 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  border: 1px solid var(--Error-400, #f97066);
  background: #fff2f1;
  mix-blend-mode: multiply;
  color: var(--Error-400, #f97066);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
}

.byname {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem;
}
.gray70020px {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
}

.popupouter {
  min-width: 300px;
  padding: 32px 16px 16px 16px;

  border-radius: 20px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  background: rgba(255, 255, 255, 0.8);
}
.popupinner {
  padding: 40px 24px 24px 24px;

  align-items: center;
  flex-shrink: 0;
  border-radius: 20px;
  background: #fff;
}
.CompanyDashboardChallengeRight {
  width: 324px;

  flex-shrink: 0;

  padding: 1rem;
  border-radius: 5px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  background: rgba(255, 255, 255, 1);
}
.Challengescardouterwhitediv {
  max-width: 250px;
  width: 250px;
  height: 281px;
  padding: 0.5rem;
  border-radius: 5px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  background: rgba(255, 255, 255, 0.4);
  transition: all 0.3 ease-in-out;
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
  transition: all 0.3s ease-in-out;
}
.Challengescardouterwhitediv:hover {
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.Challengescardinnergreydiv {
  width: 233.358px;
  height: 225px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--Secondary-25, #fff);
}
.challengescardinsidegreybackgroundimagediv {
  width: 233.358px;
  height: 125px;
  flex-shrink: 0;
  border-radius: 8px 8px 0px 0px;
}
.PreviewCompanyDashboardButton {
  display: flex;
  padding: 8px 18px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  border-radius: 5px;
  background: var(--Primary-400, #fd6f8e);
  color: var(--Snow, #fff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 114.286% */
}

.PreviewCompanyDashboardButton:hover {
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
  background: #e31b54;
}
.challengescardinsidegreybackgroundimagedivcompanycard {
  position: absolute;
  margin-top: 8px;
  margin-left: 8px;
}
.ChallengecardChallengeTitle {
  color: var(--171721-primary, #171721);
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  /* word-break: break-all; */
  width: 218px;
}
.ChallengeCardCompanyName {
  color: #e13551;
  font-family: Poppins;
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 14.5px */
  width: 200.345px;
}
.ChallengecardTaskCounter {
  color: #98a2b3;
  text-align: center;
  font-family: Poppins;
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 12.823px; /* 160.282% */
}
.addChallengeDiv {
  display: flex;
  width: 250px;
  height: 281px;
  padding: 0px 113px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 5px;
  border-top: 1.5px solid var(--Base-White, #fff);
  border-left: 1.5px solid var(--Base-White, #fff);
  background: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.addChallengeDiv:hover {
  border-top: 2px solid var(--Base-White, #fff);
  border-left: 2px solid var(--Base-White, #fff);
  background: var(--Primary-25, #fff5f6);

  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);

  /* Trans Card v2 */
}

.addChallengeDiv:hover .svg path {
  fill: #e31b54;
}
.contactusbutton {
  display: flex;
  height: 2.5rem;
  padding: 0.625rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.375rem;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: #475467;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 171.429% */
}
.feelfree {
  color: #344054;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.letsStartTogether {
  color: #0c111d;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}
.bluerightsidedivs {
  display: flex;
  padding: 6px 14.993px;
  align-items: center;
  gap: 11.995px;
  border-radius: 30px;
  border: 0.75px solid #eff0f1;
  background: linear-gradient(
      0deg,
      var(--Secondary-25, #f5f8ff) 0%,
      var(--Secondary-25, #f5f8ff) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: #0c111d;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
}
.learnmoretext {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25rem; /* 125% */
}
.belowLearnMoreText {
  color: #0c111d;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
}
.belowLearnMoreText span {
  color: #0c111d;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.25rem;
}
.newStudentPopupChips {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  border: 1px solid var(--Secondary-Color-Gray-300, #d0d5dd);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.newStudentPopupChips:hover {
  border: 1px solid var(--Secondary-Color-Gray-300, #d0d5dd);
  background: linear-gradient(
      0deg,
      var(--Secondary-Color-Gray-25, #fcfcfd) 0%,
      var(--Secondary-Color-Gray-25, #fcfcfd) 100%
    ),
    rgba(255, 255, 255, 0.2);

  /* Shadow/Blue Shadow */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.newdashboardsidechips {
  display: flex;
  height: 32px;
  padding: 6px 14px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid var(--Gray-200, #d0d5dd);
  background: linear-gradient(
      0deg,
      var(--Base-White, #fff) 0%,
      var(--Base-White, #fff) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.ChallengesCardNew-Outer {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  transition: all 0.3s ease;
  overflow: hidden; /* Ensures content doesn't overflow */
  position: relative;
}
.ChallengesCardNew-Outer-Student {
  width: 100%;

  transition: all 0.3s ease;
  overflow: hidden; /* Ensures content doesn't overflow */
  position: relative;
}
.gray40012400 {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.gray40016500 {
  color: var(--Secondary-Color-Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.gray75010500 {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
}
.ChallengesCardNew-Inner {
  background: #fff;
  min-height: 226px;
  max-height: 226px;
  border-radius: 0px 0px 5px 5px;
  padding: 1rem;
  transition: transform 0.3s ease-in-out;
}
.ChallengenewCarddescription {
  max-height: 0;
  overflow: hidden;
  /* display: none; */
  opacity: 0;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    margin-top 0.3s ease-in-out; /* Smooth transition for height and opacity */
}
@media (max-width: 1024px) {
  .ChallengenewCarddescription {
    display: block;
    margin-top: 0.5rem;
    max-height: 70px; /* Adjust this value to control the maximum height */
    opacity: 1;
  }
}

.ChallengesCardNew-Outer:hover .ChallengenewCarddescription {
  display: block;
  margin-top: 0.5rem;
  max-height: 70px; /* Adjust this value to control the maximum height */
  opacity: 1; /* Makes the description fully visible */
}
.ChallengesCardNew-Outer-Student:hover .ChallengenewCarddescription {
  display: block;
  margin-top: 0.5rem;
  max-height: 70px; /* Adjust this value to control the maximum height */
  opacity: 1; /* Makes the description fully visible */
}

/* .ChallengesCardNew-Outer:hover .ChallengesCardNew-Inner {
  transform: translateY(
    -20px
  );
} */

.country-icon {
  width: 20px; /* Adjust the width as needed */
  height: auto; /* Maintain the aspect ratio */
  margin-right: 5px; /* Optional spacing between icon and text */
}
.select-without-border {
  border: none;
  outline: none;
}
.tagscard {
  display: flex;
  padding: 4px 6px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: var(--Primary-25, #ffffff);
}

.selectedcard {
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: linear-gradient(
      0deg,
      var(--Primary-300, #fea3b4) 0%,
      var(--Primary-300, #fea3b4) 100%
    ),
    rgba(255, 255, 255, 0.2);
}
.countrylist {
  margin-top: 10px;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  font-family: Poppins;
  font-size: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.scrollbaredit::-webkit-scrollbar {
  width: 5px;

  /* Set the width of the scrollbar */
}

.scrollbaredit::-webkit-scrollbar-thumb {
  background-color: #e31b54; /* Set the color of the scrollbar thumb */
}

.scrollbaredit::-webkit-scrollbar-track {
  background-color: #fff; /* Set the color of the scrollbar track */
}
.sidebarcompanydashboardmove {
  position: fixed;

  z-index: 100;
  transition: all 0.3s ease-in-out;
}
.sidebarcompanydashboardmove.actived {
  top: 0;
  left: 0;
}
.sidebarcompanydashboardmove.inactived {
  top: 0;
  left: -250%;
}
.userimage {
  border-radius: 180px;
}
.CompanydashboardsideBar {
  min-height: 100vh;
  /* height: 100%; */
  min-width: 18.75rem;
  max-width: 18.76rem;

  background: #fff;
}
.paddingLeftTESting {
  padding-left: 18.76rem;
}
.CompanydashboardsideBarButton {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  border-radius: 8px;
  color: #101828;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
}
.CompanydashboardsideBarButton:hover {
  color: #e31b54;
}
.CompanydashboardsideBarButton:hover svg path {
  fill: #e31b54 !important;
}
.CompanyDashboardSidebarToolstext {
  color: var(--Gray-400, #98a2b3);
  font-family: "Poppins";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
  letter-spacing: 0.033rem;
  text-transform: uppercase;
}
.CompanyDashboardSidebarInside {
  margin-top: 1.875rem;
  margin-left: 1.25rem;

  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 1440px) {
  .CompanydashboardsideBar {
    min-width: 15rem;
    max-width: 15.1rem;
  }
  .paddingLeftTESting {
    padding-left: 15rem;
  }
  .CompanyDashboardSidebarInside {
    margin-top: 1.875rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-bottom: 1.25rem;
  }
}

.userName {
  color: var(--Gray-950, #0c111d);

  font-size: 30px;
  font-weight: 400;
}

.compantText {
  color: var(--Gray-700, #344054);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}
.admintext {
  color: var(--Gray-400, var(--Gray-400, #98a2b3));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.507px;
  text-transform: uppercase;
}
.bottomdiv {
  display: flex;

  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  background: var(--Secondary-25, #f9fafb);
}
.bottomdivfirst {
  color: var(--Gray-900, #101828);

  /* Text sm/Semibold */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 142.857% */
}
.bottomdivsecond {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.bottomfirstbutton {
  color: var(--Gray-600, #475467);
  /* Text sm/Semibold */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.bottomsecondbutton {
  color: var(--Primary-600, #e31b54);
  /* Text sm/Semibold */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

@media screen and (max-width: 1199px) {
  .CompanydashboardsideBar {
    min-width: 5rem;
    max-width: 5.1rem;
  }
  .paddingLeftTESting {
    padding-left: 5rem;
  }
}

@media screen and (max-width: 1023px) {
  .CompanydashboardsideBar {
    min-width: 15rem;
    max-width: 15.1rem;
  }
  .paddingLeftTESting {
    padding-left: 1.5rem;
  }
}
.megaMenu {
  /* Fixed padding */
  position: absolute;
  top: -100;
  opacity: 0;
  transition: all 0.3s ease-in-out, background 1s ease-in-out; /* Smooth transition */
  width: 100vw;
  height: 0px;
  -webkit-backdrop-filter: blur(10px);

  /* Test only the standard property */
  backdrop-filter: blur(10px);
  z-index: 6;
  overflow-y: scroll;
}

.megaMenu.megamenuactive {
  position: sticky;
  /* top: 0; */
  opacity: 1; /* Make the menu visible */

  background-color: rgba(255, 255, 255, 0.3); /* White with 0.3 opacity */
  background: rgba(12, 17, 29, 0.05);

  -webkit-backdrop-filter: blur(10px);

  /* Test only the standard property */
  backdrop-filter: blur(10px);
  height: 100vh;
}
.gradientvirtual {
  background: var(
    --All-Gradients-Diversity-Gradient,
    linear-gradient(90deg, #2d31a6 0%, #e31b54 25%, #f38a23 75%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: clamp(30px, 4vw, 60px);
  line-height: clamp(36px, 4vw, 72px);
  font-style: normal;
  font-weight: 400;
}
.secondsectionsolutions {
  background: url(https://varyance.b-cdn.net/uploads/solutionsmain.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100% 100%;
  aspect-ratio: 2.66666666667 !important;
  overflow: hidden;
  padding: 2.688rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hoverbackgroundicons {
  background: #fff;
}
.hoverbackgroundpink {
  background: #fff;
}
.hoverbackgroundpink:hover {
  background: #eaecf0;
}
.newChatText {
  display: none;
  opacity: 0;
  position: absolute;
}
.newChat-btn:hover .newChatText {
  background: #101828;
  border-radius: 5px;
  display: block;
  opacity: 1;
  top: 120%;
  line-height: 16px !important;
  padding: 0.25rem;
  margin: 0rem;
  width: 67px;

  right: 0;
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.hoverbackgroundicons:hover {
  background: #ebebeb;
}
.hovertitle {
  color: #0c111d;
  transition: all 0.3s ease-in-out;
}
.hovercolorback:hover {
  color: black !important;
}
.hoverdarkblacksvgdiv svg path {
  fill: #98a2b3;
  color: #98a2b3;
  transition: color 0.3s ease-in-out;
}
.hover-container {
  cursor: pointer;
}
.hovertest {
  background: "";
  transition: all 0.2s ease-in-out;
}
.disabled:disabled {
  color: rgba(0, 0, 0, 0.3); /* Replace '0, 0, 0' with your text color */
}
.hovertest:hover {
  background: #e31b54;
  color: #fff;
  border: 1px solid #e31b54;
}

.hover-container:hover .topnoticationright {
  color: #fff;
}

.hover-container:hover span {
  text-decoration-line: underline;
}
.skillsshow:hover .hoverdarkblacksvgdiv svg path {
  fill: #0c111d;
}
.hoveropacity {
  transition: all 0.3s ease-in-out;
}
.hoveropacity:hover {
  opacity: 0.5;
}
.MegaIconText {
  padding: 1rem 0.5rem;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
}
.MegaIconText:hover {
  background: #f9fafb;
}
.MegaIconText:hover .hovertitle {
  color: #e31b54; /* Removed quotes */
}
.secondEventsHome {
  background: url(https://varyance.b-cdn.net/uploads/eventsHome.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100% 100%;
  aspect-ratio: 2.66666666667 !important;
  overflow: hidden;
  padding: 2.688rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hoverredfill:hover * {
  fill: #e31b54 !important;
  color: #e31b54;
}
.hoverred {
  transition: color 0.3s ease-in-out;
}
.hoverblack {
  transition: color 0.3s ease-in-out;
}

.hoverblack:hover {
  color: #0c111d;
}
.hoverredsvgstroke svg path {
  transition: all 0.3s ease-in-out;
}
.hoverredsvgstroke:hover svg path {
  stroke: #e31b54;
  stroke-width: 1px;
}
.hoverredsvgstroke16 svg path {
  transition: all 0.3s ease-in-out;
}
.hoverredsvgstroke16:hover svg path {
  stroke: #e31b54;
  stroke-width: 1.6px;
}
.hoverredsvg {
  transition: all 0.3s ease-in-out;
}
.hoverredsvg:hover svg path {
  fill: #e31b54;
}
.hoverdarkred {
  transition: color 0.3s ease-in-out;
}
.hoverred:hover {
  color: #e31b54;
}
.hovercolorback:hover {
  color: black;
}
.registeration {
  color: var(--Gray-900, #101828);

  font-size: clamp(1.5rem, 4vw, 2.25rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(2rem, 4vw, 3rem); /* 126.667% */
  align-self: stretch;
}
.livediv {
  display: inline-flex;
  padding: 2px 6px;
  align-items: center;
  gap: 4px;
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.typediv {
  display: inline-flex;
  padding: 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-950, #0c111d);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
}
.hoverdarkwhite {
  background: #fff;
  transition: background 0.3s ease-in-out;
}
.hoverdarkwhite:hover {
  background: #fcf2f2;
}
.onclickscale {
  scale: 1; /* Default scale */
  transition: transform 0.2s ease; /* Smooth scaling animation */
}

.onclickscale:active {
  transform: scale(0.9); /* Scale down when clicked */
}
.hoverdarkblacksvg {
  fill: #98a2b3;
  color: #98a2b3;
  transition: color 0.3s ease-in-out;
}
.hoverdarkredsvg {
  transition: color 0.3s ease-in-out;
}
.hoverdarkredsvg:hover {
  fill: #c01048 !important;
  color: #c01048 !important;
}
.hoverdarkredsvg:hover svg path {
  fill: #c01048 !important;
}

.hoverdarkblacksvg:hover {
  fill: #0c111d;
}
.hoverdarkred:hover {
  color: #c01048;
}
.hoverdarkblack {
  transition: color 0.3s ease-in-out;
}
.hoverdarkblack:hover {
  color: #0c111d;
}
.secondAnalyticsHome {
  background: url(https://varyance.b-cdn.net/uploads/analyticshome.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100% 100%;
  aspect-ratio: 2.66666666667 !important;
  overflow: hidden;
  padding: 2.688rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.secondmessengerHome {
  background: url("https://varyance.b-cdn.net/uploads/Frame%202147225748%20(1).png");
  background-repeat: no-repeat;

  background-position: right;
  background-size: 100% 100%;
  aspect-ratio: 2.66666666667 !important;
  overflow: hidden;
  padding: 2.688rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.secondstudentorgHome {
  background: url("https://varyance.b-cdn.net/uploads/Screen%202%20Section%20(2).png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100% 100%;
  aspect-ratio: 2.66666666667 !important;
  overflow: hidden;
  padding: 2.688rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.secondjobpostsHome {
  background: url("https://varyance.b-cdn.net/uploads/Frame%202147225751%20(1).png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100% 100%;
  aspect-ratio: 2.66666666667 !important;
  overflow: hidden;
  padding: 2.688rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sameSectiom-outer {
  padding: 60px 0px;
}
.loginbackground {
  height: 100vh;
  width: 100vw;
  background: url(https://varyance.b-cdn.net/uploads/newregisterbackground.png);
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .loginbackground {
    height: 100svh;
    width: 100svw;
  }
  .headerBackgroundChange {
    margin-top: 0px !important;
  }
}

@media (max-width: 1024px) {
  .secondsectionsolutions,
  .secondEventsHome,
  .secondAnalyticsHome,
  .secondmessengerHome,
  .secondjobpostsHome,
  .secondstudentorgHome {
    aspect-ratio: 2 !important;
  }
}
@media (max-width: 768px) {
  .secondsectionsolutions,
  .secondEventsHome,
  .secondAnalyticsHome,
  .secondmessengerHome,
  .secondjobpostsHome,
  .secondstudentorgHome {
    aspect-ratio: 1.6 !important;
  }
}

.gray60011400 {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 145.455% */
}
.gray60014400 {
  color: var(--Secondary-Color-Gray-600, #475467);

  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.dropdowntopbutton {
  display: flex;
  padding: 11px 16px;
  width: 100%;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px 5px 0px 0px;
  border: none;
  border-bottom: 1px solid #dee3e6;
  background: var(--Solid-White, #fff);
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.dropdownbottombutton {
  display: flex;
  padding: 11px 16px;
  align-items: flex-start;
  width: 100%;
  border: none;
  gap: 10px;
  align-self: stretch;
  border-radius: 0px 0px 5px 5px;
  background: var(--Solid-White, #fff);
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.dropdownbottombutton:hover {
  color: #101828;
}

.dropdownmiddlebutton:hover {
  color: #101828;
}
.dropdowntopbutton:hover {
  color: #101828;
}
.challenge-details-type {
  display: flex;
  color: #e31b54;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 16px */
  padding: 6px 10px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 30px;
}

.challenge-detail-user-cover {
  padding: 8px;

  gap: 12px;

  border-radius: 5px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: #fff;
}
.levelofengagementdetails {
  display: flex;
  width: 173px;
  padding: 6px 14.993px;
  align-items: center;
  gap: 11.995px;

  border-radius: 30px;
  border: 0.75px solid var(--Gray-300, #d0d5dd);
  background: linear-gradient(
      0deg,
      var(--Gray-25, #fcfcfd) 0%,
      var(--Gray-25, #fcfcfd) 100%
    ),
    rgba(255, 255, 255, 0.2);
}

.dropdownmiddlebutton {
  display: flex;
  padding: 11px 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: var(--Solid-White, #fff);
  border: none;
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  border-bottom: 1px solid #dee3e6;
  width: 100%;
}
/* Basic button styling */
.hiddennames * {
  transition: all 0.1s ease-in-out;
}
.pulsingButton {
  /* width: 220px; */
  /* text-align: center;
  white-space: nowrap; */
  display: block;
  box-shadow: none;
  box-shadow: 0 0 0 0 rgba(232, 76, 61, 0.7);
  border-radius: 10px;
  background-color: #ff0000;
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-size: 22px;
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  color: #ffffff;
  transition: all 300ms ease-in-out;
}
@-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@keyframes pulsing2 {
  to {
    box-shadow: 0 0 0 10px rgba(208, 213, 221);
  }
}
@-webkit-keyframes pulsing2 {
  to {
    box-shadow: 0 0 0 10px rgba(208, 213, 221, 0);
  }
}

@-moz-keyframes pulsing2 {
  to {
    box-shadow: 0 0 0 10px rgba(208, 213, 221, 0);
  }
}

@-ms-keyframes pulsing2 {
  to {
    box-shadow: 0 0 0 10px rgba(208, 213, 221, 0);
  }
}

@keyframes pulsing2 {
  to {
    box-shadow: 0 0 0 10px rgba(208, 213, 221, 0);
  }
}

.pulsingButton2 {
  /* width: 220px; */
  /* text-align: center;
  white-space: nowrap; */
  display: block;

  box-shadow: 0 0 0 0 rgba(208, 213, 221, 0.7); /* Updated color */
  border-radius: 10px;
  background-color: #ff0000;
  -webkit-animation: pulsing2 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing2 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing2 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing2 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  font-size: 22px;
  font-weight: normal;
  font-family: sans-serif;
  text-decoration: none !important;
  color: #ffffff;
  transition: all 300ms ease-in-out;
}

.hoverinvert {
  padding: 12px 24px;

  border-radius: 6px;
  border: 1px solid #e31b54;
  background: #e31b54;
  color: var(--Snow, #fff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.hoverinvert:hover {
  background: #fff;
  color: #e31b54;
}

.topnoticationtext {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
  line-height: 20px; /* 142.857% */
}
.topnoticationtext span {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.topnoticationright {
  color: transparent;
  transition: all 0.3s ease-in-out;
}
.hover-container {
  cursor: pointer;
}

.hover-container:hover .topnoticationright {
  color: #fff;
}

.hover-container:hover span {
  text-decoration-line: underline;
}
.topnotificationbar {
  padding: 8px 0px;

  background: linear-gradient(180deg, #344054 0%, #101828 105.56%);
}

.hovertest {
  background: "";
  transition: all 0.2s ease-in-out;
}
.disabled:disabled {
  color: rgba(0, 0, 0, 0.3); /* Replace '0, 0, 0' with your text color */
}
.hovertest:hover {
  background: #e31b54;
  color: #fff;
  border: 1px solid #e31b54;
}
.companywebchip {
  color: #7835cd;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
}
a.pulsingButton2:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  animation: none;
  color: #ffffff;
}
/* Comment-out to have the button continue to pulse on mouseover */

.pulsingButton:hover {
  -webkit-animation: none;
  -moz-animation: none;
  -ms-animation: none;
  box-shadow: none !important;

  animation: none !important;
  color: #ffffff;
}

/* Animation */

@-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

@keyframes pulsing {
  to {
    box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
  }
}

.headernew {
  position: sticky;
  z-index: 101;
  top: 0;
  width: 100vw;
  background: #fff;
  transition: all 0.3s ease-in-out;
}

.header {
  position: fixed;
  z-index: 101;
  top: 0;
  width: 100vw;
  background: #fff;
  transition: all 0.3s ease-in-out;
}
.filmo48400 {
  color: var(--Base-White, #fff);

  font-size: clamp(24px, 4vw, 48px);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(32px, 4vw, 64px); /* 133.333% */
}
.paddingcontactus {
  padding: 60px;
}
@media screen and (max-width: 1024px) {
  .paddingcontactus {
    padding: 40px;
  }
}
@media screen and (max-width: 768px) {
  .paddingcontactus {
    padding: 30px 16px;
  }
}
.white20500 {
  color: var(--Base-White, #fff);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.contactusBackground {
  display: flex;
  justify-content: center;
  /* padding: 120px 200px; */
  align-items: center;
  background: #cf194c;
  background: url(https://varyance.b-cdn.net/uploads/contactus.png);
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100% 100%;
}

.ShortListTalentouter {
  display: flex;
  padding: 24px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 20px 0px 0px 20px;
  background: #fff;
}
.ShortListTalentouter.activepadding {
  padding: 24px;
}

.ShortListTalenttitle {
  color: var(--Gray-950, #0c111d);

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px; /* 140% */
}

.ShortListTalenttitle span {
  color: var(--Primary-600, #e31b54);

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
}

@media screen and (max-width: 768px) {
  .ShortListTalentouter {
    padding: 16px;
  }
  .ShortListTalenttitle,
  .ShortListTalenttitle span {
    font-size: 24px;
  }
}
.testingscale {
  width: 100%;
  max-width: 890px;
  height: auto;
}
.newHomeSectionCOmpany {
  background: rgba(242, 244, 247, 0.8);
}

.headernew.hidden {
  top: -50%;
  pointer-events: none;
}

.header.hidden {
  top: -50%;
  pointer-events: none;
}

.closebutton {
  color: #0c111d;

  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.headerhomepageCompany {
  display: flex;
  width: 100vw;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--Gray-200, #eaecf0);

  justify-content: center;
  align-items: center;
}
.smallheadingcompany {
  color: #0c111d;
  font-family: Poppins;
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-style: normal;
  font-weight: 500;
  line-height: clamp(1.5rem, 5vw, 2rem);
}

.headerCompany {
  width: 73.125rem;
}
.headerCompanytest {
  min-width: 73.125rem;
}

.justifySpace {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.logoimage {
  width: 2.25rem;
  height: 2.25rem;
  filter: drop-shadow(0px 0.23px 0.184px rgba(0, 49, 122, 0.02))
    drop-shadow(0px 0.552px 0.442px rgba(0, 49, 122, 0.03))
    drop-shadow(0px 1.04px 0.832px rgba(0, 49, 122, 0.04))
    drop-shadow(0px 1.856px 1.485px rgba(0, 49, 122, 0.04))
    drop-shadow(0px 3.471px 2.777px rgba(0, 49, 122, 0.05))
    drop-shadow(0px 8.308px 6.646px rgba(0, 49, 122, 0.07));
}
.mainheadingcompany {
  color: #101828;

  font-size: clamp(32px, 4vw, 60px);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(36px, 4vw, 72px);
}
.margintopcompany {
  margin-top: clamp(140px, 15vw, 182px);
}

.Companypagelogoimages {
  width: 50px;

  height: 50px;
}
.maxWidthClampedCompanysmalltext {
  max-width: 180px;
}

.engagemarginTopCompany {
  margin-top: clamp(1rem, 4vw, 1.5rem);
}
.challengeengageCompany {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: clamp(16px, 4vw, 18px);
  font-style: normal;
  font-weight: 500;
  line-height: clamp(24px, 4vw, 28px); /* 155.556% */
}
.challengeengageCompany > span {
  color: #0c111d;
  font-family: Poppins;
  font-size: clamp(16px, 4vw, 18px);
  font-style: normal;
  font-weight: 500;
  line-height: clamp(24px, 4vw, 28px);
}
.secondSectionMargintopCompany {
  margin-top: clamp(4.563rem, 4vw, 2.625rem);
}
.sparkInterest {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
}
.sparkInterest > span {
  color: #0c111d;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}
.newheadingtextthirdsection {
  color: var(--Gray-950, #0c111d);
  text-align: center;
  font-size: clamp(1.5rem, 4vw, 1.875rem);

  font-style: normal;
  font-weight: 400;
  line-height: clamp(2.25rem, 4vw, 2.75rem);
}
.newheadingtextthirdsection > span {
  color: var(--Primary-600, #e31b54);

  font-size: clamp(1.5rem, 4vw, 1.875rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(2.25rem, 4vw, 2.75rem);
}
.thirdsectiondescription {
  color: var(--Gray-950, #0c111d);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.thirdsectiondescription > span {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.underSparkInterest {
  margin-top: 1.25rem;
  color: #667085;

  font-size: clamp(1.5rem, 4vw, 2.25rem);
  line-height: clamp(2rem, 4vw, 3.806rem);
  font-style: normal;
  font-weight: 400;
  /* 60.9px */
  letter-spacing: -1.2px;
}
.imageborderremove:focus {
  outline: none !important;
  border: none !important;
}

.gray95020400 {
  color: var(--Gray-950, #0c111d);

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
}
.bluespans {
  color: #6172f3;

  font-style: normal;
  font-weight: 400;
  font-size: clamp(1.5rem, 4vw, 2.25rem);
  line-height: clamp(1.5rem, 4vw, 2.806rem);
  letter-spacing: -1.2px;
}
.gray70016400 {
  color: var(--Secondary-Color-Gray-700, #344054);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
}
.gray95016500 {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.gray95016400 {
  color: var(--Gray-950, #0c111d);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.blackspans {
  color: #0c111d;

  font-size: clamp(1.5rem, 4vw, 2.25rem);
  line-height: clamp(1.5rem, 4vw, 2.806rem);
  font-style: normal;
  font-weight: 400;

  letter-spacing: -1.2px;
}
.pinkspans {
  color: #e31b54;
  font-size: clamp(1.5rem, 4vw, 2.25rem);
  line-height: clamp(1.5rem, 4vw, 2.806rem);

  font-style: normal;
  font-weight: 400;

  letter-spacing: -1.2px;
}
.engageEarly {
  margin-top: clamp(1rem, 4vw, 2rem);
  color: #1d2939;
  font-family: Poppins;
  font-size: clamp(0.75rem, 4vw, 1rem);
  font-style: normal;
  font-weight: 500;
  line-height: clamp(1.5rem, 4vw, 1.75rem); /* 155.556% */
}
.engageEarly > span {
  color: #1d2939;
  font-family: Poppins;
  font-size: clamp(0.75rem, 4vw, 1rem);
  font-style: normal;
  font-weight: 700;
  line-height: clamp(1.5rem, 4vw, 1.75rem);
}

.css-blurry-gradient {
  position: fixed;

  transform: translate(-50%, 0%);
  width: 800px;
  height: 800px;
  border-radius: 50% 22% 40% 80%;
  filter: blur(100px);
  background: radial-gradient(
    circle at 50% 50%,
    rgba(254, 163, 180, 0.3),
    rgba(254, 163, 180, 0.3)
  );
  opacity: 0.2;
}
.svg-content {
  width: 70%;

  height: 100%;
  flex-shrink: 0;
  /* fill: rgba(254, 163, 180, 0.30); */
  /* filter: blur(187.63021850585938px); */
}
.svg-blue {
  width: 100%;
  height: 100%;
  margin-top: -100px;
  flex-shrink: 0;
}
.exemplarymargintopCompany {
  margin-top: clamp(2rem, 4vw, 2.5rem);
}
.marginTOpFourthCompany {
  margin-top: clamp(5rem, 20vw, 16.25rem);
}
.arrowdivsCompany {
  display: inline-flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 50px;
  background: #fff;
  cursor: pointer;
}
.stylestest {
  display: flex;
  height: 250vh;
  position: relative;
}
.stylestestnew {
  display: flex;
  height: 200vh;
  position: relative;
}
.NewbackgroundCompany {
  background: url(https://varyance.b-cdn.net/uploads/newCompanyBackground1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.newSectionbackground {
  background: url(https://varyance.b-cdn.net/uploads/1920.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.menu {
  /* Your menu styles */
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 100;
  display: flex;
  justify-content: center;
  position: fixed;
  transition: all 0.3s ease-in-out; /* Add transition */
}

.menu.activee {
  top: 0;
  right: 0;
}

.menu.inactive {
  top: 0;
  right: -200%;
}
.innersolutionsdiv {
  position: absolute;
  transition: right 0.3s ease-in-out;
  right: 100%;
  width: 100%;
  height: 100%;
  padding: 40px 20px;
}
.newhomesmallheading {
  color: var(--Gray-950, #0c111d);

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}
.newhomesmalltext {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.innersolutionsdiv.activeeee {
  right: 0%;
}
.gray95024400 {
  color: var(--Gray-950, #0c111d);

  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 31.2px */
}

.gray80016500 {
  color: var(--Secondary-Color-Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.overflowshown * {
  overflow: visible;
}
.innersolutionsdiv.inactiveeee {
  right: -100%;
}
.innermenunew {
  position: relative;
  transition: right 0.3s ease-in-out;
  right: 200%; /* Starts off-screen */
}

.innermenunew.activeee {
  right: 200%;
}

.innermenunew.inactiveee {
  right: 0%;
}
.newmenu {
  /* Your menu styles */
  width: 100vw;
  height: 100vh;
  background: #f9fafb;
  z-index: 100;
  display: flex;
  justify-content: center;
  position: fixed;
  transition: all 0.3s ease-in-out; /* Add transition */
}

.newmenu.activee {
  top: 0;
  right: 0;
}

.newmenu.inactive {
  top: 0;
  right: -200%;
}

.Loginbuttoninmenu {
  color: #e31b54;
  margin-top: 55px;
  background: none;
  border: none;
  font-size: 17.507px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 111.111% */
  transition: all 0.3s ease-in-out;
}

.menubuttons {
  color: #0c111d;

  text-align: start;
  background: none;
  border: none;
  font-size: clamp(24px, 4vw, 32px);
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
  transition: all 0.3s ease-in-out;
}

.menubuttons:hover {
  color: #e31b54;
}
.menutextinmenu {
  color: #98a2b3;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.067px; /* 142.222% */
}
.headermenumargintop {
  margin-top: clamp(60px, 10vw, 140px);
  gap: clamp(60px, 10vw, 129px);
}
.fullwidthscroller {
  gap: 40px;
  width: 100%;
  overflow-x: visible;
}
.testingscrolls {
  position: sticky;
  top: 0%;
  width: 100% !important;
}

.testingscrollsnew {
  position: sticky;
  top: 15%;
  width: 100% !important;
}

.card1Company {
  display: flex;
  width: 405px;
  height: 520px;
  overflow: hidden;
  flex-direction: column;

  flex-shrink: 0;
  border-radius: 5px;
  background: #fff;
}
.gray12 {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
.card1paddings {
  padding: 18px 18px 0px 18px;
}
.firstcard1button {
  display: flex;

  height: 26px;
  padding: 16px 10px;
  justify-content: center;
  align-items: center;
  gap: 10.892px;
  border-radius: 100px;
  border: 1.5px solid var(--Primary-600, #e31b54);
  background: rgba(255, 255, 255, 0.1);
}
.firstcard1buttontext {
  color: var(--Primary-600, #e31b54);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.338px; /* 136.152% */
}
.firstcard2ndtext {
  color: #0c111d;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}
.firstcard3rdtext {
  margin-top: 0.75rem;
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.961px; /* 139.741% */
}
.firstcard3rdtext > span {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20.961px;
}
.companyCard2nd {
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url(https://varyance.b-cdn.net/uploads/company2ndcard.png) lightgray;
  min-width: 960px;
  min-height: 520px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.card2button1 {
  display: flex;

  height: 26px;
  padding: 16px 10px;
  justify-content: center;
  align-items: center;
  gap: 10.892px;
  border-radius: 100px;
  border: 1.5px solid var(--Base-White, #fff);
  background: rgba(255, 255, 255, 0.1);
}
.allpoppins14 * {
  color: var(--171721-primary, #171721);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;

  line-height: 21px; /* 150% */
}
.selectedrolecard {
  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #fd6f8e;
  background: var(--Primary-25, #fff5f6);

  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.headercreate {
  display: flex;

  padding-top: clamp(0.5rem, 4vw, 1rem);
  padding-bottom: clamp(0.5rem, 4vw, 1rem);
  padding-left: clamp(1.25rem, 4vw, 3.75rem);
  padding-right: clamp(1.25rem, 4vw, 3.75rem);
  justify-content: space-between;
  align-items: center;
  background: #fff;
}

.navrounddiv {
  display: flex;
  width: 26px;
  padding: 5px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  border: 1.3px solid var(--Gray-300, #d0d5dd);
  background: var(--Gray-25, #fcfcfd);
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
.navrounddivtext {
  color: var(--Gray-500, #667085);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
.navrounddivtextactive {
  color: var(--Primary-600, #e31b54);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
.navrounddivactive {
  color: var(--Primary-600, #e31b54);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  display: flex;
  width: 26px;
  padding: 5px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
  border: 1.3px solid var(--Primary-600, #e31b54);
  background: var(--Primary-25, #fff5f6);
}

.savedraft {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.basicInforMationPADDINGS {
  padding-top: 2.5rem;
  padding-left: clamp(1.25rem, 4vw, 3.75rem);
  padding-right: clamp(1.25rem, 4vw, 3.75rem);
}
.BasicInforMationHeading {
  color: var(--Gray-950, #0c111d);

  font-size: 1.5rem;

  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 32.4px */
}
.UnderBasicHeading {
  color: #1d2939;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
}
.UnderBasicHeading span {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.challengetypetext {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 16px */
}
.black16 {
  color: var(--Gray-950, #0c111d);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.black18 {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}
.black16500 {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.black14500 {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.newblack18 {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 4 00;
  line-height: 28px;
}
.newblack18 span {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.circlebackground {
  background: url(https://varyance.b-cdn.net/uploads/Circle%20Group.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.svgpngtext {
  color: #475467;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}
.fullscreenheightwidth {
  min-height: 100vh;
  min-width: 100vw;
  background: #f2f3f7;
}
.jobcreatereactquill {
  border-radius: "5px";
  overflow: hidden;
}
.jobcreatereactquill .ql-editor {
  max-height: 255px;
  min-height: 255px;
  border-radius: 0px 0px 5px 5px;
}
.jobcreatereactquill .ql-toolbar.ql-snow {
  border-radius: 5px 5px 0px 0px;
  border-top: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-radius: 1px solid #d9d9d9;
}
.jobcreatereactquill .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-radius: 0px 0px 5px 5px;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-radius: 1px solid #d9d9d9;
}

@media (max-width: 768px) {
  .fullscreenheightwidth {
    min-height: 100svh;
  }
}

@media (min-width: 1200px) {
  .grid-auto-columns {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .grid-auto-columns {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .grid-auto-columns {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .grid-auto-columns {
    grid-template-columns: repeat(1, 1fr);
  }
}

.allblack14 * {
  color: #475467;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.reactquillfontfix * {
  font-family: Poppins;
  border-radius: 5px;
  font-size: 14px;
  line-height: 20px;
}
.gray50014400 {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.thumbnailcreatetext {
  color: var(--Gray-800, #1d2939);

  /* Caption/Caption Text */
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
}
.thumbnailcreate {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.selectedrolecardmobile {
  border-radius: 8px;
  border: 1px solid #fd6f8e;
  background: var(--Primary-25, #fff5f6);
}
.underthumbnailcreate {
  color: #475467;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.zindex1000 {
  z-index: 1000 !important;
  position: relative;
}
.popup-outer-full {
  width: "100vw" !important;
  height: "100vh" !important;
  position: fixed !important;
  z-index: 9999 !important;
}
.rolecards {
  flex-shrink: 0;
  border-radius: 8px;
  background: #fff;
  cursor: pointer;

  border: 1px solid #fff;
  transition: all 0.2s ease-in-out;
}
.rolecards:hover {
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.basicInforMationPADDINGS .slick-track {
  min-height: 300px !important;
}

.gray14 {
  color: #5a5a89;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.allpoppins * {
  font-family: Poppins;
}
.gray14950 {
  color: #0c111d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.gray14400 {
  color: #344054;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.sectionSectiontitle {
  color: var(--Gray-950, #0c111d);

  font-size: clamp(2rem, 4vw, 1.25rem);
  font-style: normal;
  font-weight: 400;
  word-wrap: "break-word";
  word-break: break-all;
  line-height: clamp(2rem, 4vw, 1.5rem);
}
.rolecardsheading {
  color: #0c111d;
  font-feature-settings: "clig" off, "liga" off;

  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.newCompanyChips {
  display: flex;
  height: 32px;
  padding: 6px 14px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: linear-gradient(
      0deg,
      var(--Base-White, #fff) 0%,
      var(--Base-White, #fff) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.centerimportant {
  justify-content: center !important;
}

.rolecardstext {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.gray80014400 {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.gray80016500 {
  color: var(--Secondary-Color-Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.supportbtnHeader {
  display: flex;

  height: 36px;
  padding: 8px 12.283px;
  justify-content: center;
  align-items: center;
  gap: 6.142px;
  border-radius: 4px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: rgba(255, 255, 255, 0.6);
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.supportbtnHeader:hover {
  color: #e31b54;
}
.secondcard1buttontext {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.338px; /* 136.152% */
}
.Secondcard2ndtext {
  color: #fff;
  margin-top: 1rem;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 23.956px;
}
.Secondcard3rdtext {
  margin-top: 10px;
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.961px; /* 139.741% */
}
.testingmotiondiv {
  gap: "40px";
  width: "100%";
}
.gray95014400 {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.company3rdcard {
  /* Ensure relative positioning for the pseudo-element */
  overflow-x: clip;
  width: 330px;
  height: 520px !important;
  flex-shrink: 0;
  /* background: url(https://varyance.b-cdn.net/uploads/boxgradient.png); */

  border-radius: 10px;
  background: #fff;

  /* Trans Card v2 */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.companyCard4 {
  background-color: #fd6f8e;
  width: 330px;
  min-height: 520px !important;
}
.card4text {
  color: #fff;
  margin-top: 2.3rem;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 22px */
}
.letstalkbutton {
  display: flex;
  background: none;
  color: #fff;
  padding: 8px 20px;
  font-family: "Poppins";
  justify-content: center;
  align-items: center;
  gap: 8.189px;
  border-radius: 5px;
  border: 2px solid #fff;

  /* Trans Card v2 */
}

@media screen and (max-height: 650px) {
  .testingscrolls {
    top: 0%;
  }
}
@media screen and (max-width: 1440px) {
  .headerhomepageCompany {
    padding: 8px 100px;
  }
  .marginsleftrightCompany {
    margin-left: 100px;
    margin-right: 100px;
  }
  .paddingsLeftRightCompany {
    padding-right: 100px;
  }
}
@media screen and (max-width: 1270px) {
  .headerCompany {
    width: 100%;
  }
  .headerCompanytest {
    min-width: 100%;
  }
}
@media (max-width: 1024px) {
  .headerhomepageCompany {
    padding: 8px 80px;
  }
  .Companypagelogoimages {
    width: 40px;
    height: 40px;
  }
  .maxWidthClampedCompanysmalltext {
    max-width: 130px;
  }
  .marginsleftrightCompany {
    margin-left: 80px;
    margin-right: 80px;
  }
  .paddingsLeftRightCompany {
    padding-right: 80px;
  }
}
@media (max-width: 768px) {
  .headerhomepageCompany {
    padding: 6px 40px;
  }
  .marginsleftrightCompany {
    margin-left: 40px;
    margin-right: 40px;
  }
  .paddingsLeftRightCompany {
    padding-right: 40px;
  }
}

@media (max-width: 450px) {
  .card1Company {
    width: 90vw !important;
  }
  .topnoticationtext {
    font-size: 12px;
  }
  .topnoticationtext span {
    font-size: 12px;
  }
  .companyCard2nd {
    max-width: 90vw !important;
    min-width: 89vw !important;
  }
  .company3rdcard {
    width: 90vw !important;
  }
  .headerhomepageCompany {
    padding: 6px 20px;
  }
  .marginsleftrightCompany {
    margin-left: 20px;
    margin-right: 20px;
  }
  .companyCard4 {
    width: 90vw !important;
  }
}
@media (max-width: 500px) {
  .newmenu {
    width: 100vw;
  }
}
/* @tailwind base;
@tailwind components;
@tailwind utilities; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html {
  scroll-snap-type: y mandatory;

  /* Add any other styles */
}
.buttonblack12 {
  color: #101828;
  text-align: start;
  background: transparent;
  border: none;
  padding: 0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
}
.buttonblack12:hover {
  color: #e31b54;
}
.gray12 {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
.hoverredsvg {
  stroke: #292d32;
  transition: stroke 0.3s ease-in-out;
}
.hoverredsvg:hover {
  stroke: #e31b54 !important;
}
.globalhoverred:hover {
  color: #e31b54;
}
.Toastify * {
  font-family: Poppins;
  font-size: 1rem;
  color: "#101828";
}
.center {
  display: flex;
  justify-content: center;
}
.space {
  display: flex;
  justify-content: space-between;
}

.interest_Item {
  display: flex;
  padding: 0.625rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.875rem;
  border-top: 2px solid var(--Base-White, #fff);
  border-left: 2px solid var(--Base-White, #fff);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.interest_Item.active {
  color: #fff;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  border-color: #fecdd6;
  background: var(--Primary-400, #fd6f8e);
  /* Trans Card: Icons */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
}

.interest_Item.active:hover {
  background: var(--primary-300, #fea3b4);
  box-shadow: none;
}

.interest_Item:hover {
  background: var(--primary-50, #fff1f3);

  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.datetime-wrapper {
  position: relative;
  width: 100%;
}

.datetime-wrapper .newinputs {
  padding-right: 2rem; /* Add spacing for the icon */
}

.datetime-wrapper .calendar-icon {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  pointer-events: none; /* Icon won't block input interaction */
}
.interest_Item .Interest_text {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
}

.interest_Item p {
  text-align: center;
  margin: auto auto;
}

.interest_Item .interest_icon {
  height: 24px;
  width: 24px;
}

.marginsleftrightCompany {
  margin-left: 6.25rem;
  margin-right: 6.25rem;
}
.paddingleftrightnew {
  margin-left: 6.25rem;
  margin-right: 6.25rem;
}
@media (max-width: 1024px) {
  .marginsleftrightCompany {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }
  .paddingleftrightnew {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .interest_Item:hover {
    background: none;

    box-shadow: none;
  }
}
.paddingAdjust {
  padding: 1.5rem;
}
@media (max-width: 768px) {
  .marginsleftrightCompany {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .paddingAdjust {
    padding: 1.25rem;
  }
  .paddingleftrightnew {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}
@media (max-width: 500px) {
  .marginsStudent {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .marginsleftrightCompany {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

@media (max-width: 450px) {
  .marginleft20 {
    margin-left: 1.25rem;
  }
  .paddingleftrightnew {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

.snap-section {
  scroll-snap-align: start;
  /* Add any other styles */
}
.shadowa {
  /* Trans Card v2 */
  /* blue */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.hovershadowa {
  transition: all 0.3s ease-in-out;
}
.hovershadowa:hover {
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.testingshadow {
  /* Trans Card v2 */
  /* blue */
  box-shadow: inset 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    inset 0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    inset 0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    inset 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.leftshadow {
  box-shadow: 0px 0px 10pxpx 0px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: 3px 0px 10px 0px rgba(0, 0, 0, 0.05) inset;
}

.lightshadow {
  /* blue */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
/* .ql-container {
  min-height: 300px;
} */
.pink16 {
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.pink12hover {
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
  cursor: pointer;
}
.pink12hover:hover {
  color: #c01048;
}
.theme16 {
  font-size: 1.6rem;
}

.theme16 {
  color: #060606;

  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.625rem; /* 116.667% */
}
.pink14 {
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.pink12 {
  color: var(--primary-500-p, var(--Primary-500, #f63d68));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.pink20 {
  color: #e31b54;

  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
}
.selected_interests_new {
  border-radius: 30px;
  border: 1px solid var(--Primary-500, #f63d68);
  background: var(--Primary-600, #e31b54);
}

.selected_interests_new:hover {
  background: var(--Primary-600, #e31b54);
}

.ql-editor {
  min-height: 300px;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.smallshadow {
  /* Trans Card: Icons */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
}
.transition * {
  transition: all 0.3s ease-in-out;
}
.profileName {
  color: var(--Gray-900, #101828);
  /* Header/Header 2 */

  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}

.newinputs {
  display: flex;
  padding: 12px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  transition: all 0.3s ease-in-out;
}
.shadowanone {
  box-shadow: none !important;
}
.hoversmallchallenge {
  background: none;
  transition: all 0.3s ease-in-out;
}
.hoversmallchallenge:hover {
  background: #f9fafb;
}
.hoversmallchallenge:hover .gray95014400 {
  color: #e31b54;
}

.newinputs::placeholder {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.newinputs:hover {
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.newinputs:focus {
  outline: none;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.newinputs.success {
  border-radius: 8px;
  border: 1px solid var(--Success-600, #079455);
  background: var(--Base-White, #fff);
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.newinputs.error {
  border-radius: 8px;
  border: 1px solid var(--Error-400, #f97066);
  background: var(--Base-White, #fff);
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.gray700 {
  fill: "#344054";
}
.graybody14500 {
  color: var(--Gray-shades-Body, #464c53);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.gray70020px {
  color: var(--Gray-700, #344054);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 160% */
}
.gray40016500 {
  color: var(--Secondary-Color-Gray-400, #98a2b3);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.gray75010500 {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 140% */
}
.companyCard {
  border-radius: 8px;
  border: 1px solid var(--e-4-e-6-e-8, #e4e6e8);
  background: #fff;
}

.searchCompanyInput:focus {
  border-bottom-color: transparent;
  outline: none;
}
.noresult {
  color: var(--171721-primary, #171721);
  font-feature-settings: "clig" off, "liga" off;
  /* Heading / H5 */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}
.cardbutton {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: var(--Gray-600, #475467);
}

.selectbutton {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  width: 98px;
  height: 44px;
}

.scrollbaredit::-webkit-scrollbar {
  width: 5px;

  border-radius: 8px;
  /* Set the width of the scrollbar */
}

.scrollbaredit::-webkit-scrollbar-thumb {
  background-color: #e31b54; /* Set the color of the scrollbar thumb */
}

.scrollbaredit::-webkit-scrollbar-track {
  background-color: #fff; /* Set the color of the scrollbar track */
}
@media screen and (width<= 652px) {
  .innercard {
    width: 90vw; /* Adjust the vw unit as needed */
  }
  .noresult {
    font-size: 14px;
  }
}

.searchicon {
  display: flex;
  width: 56px;
  height: 56px;
  padding: 13.5px 14px 14.5px 14px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.graybarekit::-webkit-scrollbar {
  width: 5px;

  /* Set the width of the scrollbar */
}

.graybarekit::-webkit-scrollbar-thumb {
  background-color: #d0d5dd;
  border-radius: 10px; /* Set the color of the scrollbar thumb */
}

.graybarekit::-webkit-scrollbar-track {
  background-color: #fff; /* Set the color of the scrollbar track */
}

.cardcompanystore {
  border-radius: 8px;
  /* border: 1px solid var(--e-4-e-6-e-8, #e4e6e8); */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.companyTitle {
  color: var(--171721-primary, #171721);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.companyLocation {
  margin-top: 4px;
  color: #344054;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
}
.welcome {
  color: var(--Gray-800, #1d2939);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.cantfind {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.cantfind span {
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}
.backtologin {
  margin-top: 2rem;
  color: var(--Gray-600, #475467);

  /* Text sm/Semibold */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.white24400 {
  color: var(--White, #fff);

  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}
.white14400 {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}
.gray5016400 {
  color: var(--Secondary-Color-Gray-50, #f9fafb);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.gray30014400 {
  color: var(--Secondary-Color-Gray-300, #d0d5dd);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 171.429% */
}
.hoveropacity {
  transition: all 0.3s ease-in-out;
}
.hoveropacity:hover {
  opacity: 0.5;
}
html,
body {
  height: 100%;
}
.StudentRegBackground {
  min-height: 100vh;
  height: 100%;
  min-width: 100vw;

  background: url(https://varyance.b-cdn.net/uploads/newregisterbackground.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.fjadsbjkfbasfbja .slick-slide {
  width: 356px !important;
}
.fjadsbjkffadsfdasfdasfbasfbja .slick-slide {
  width: 236px !important;
}

@media (max-width: 396px) {
  .fjadsbjkfbasfbja .slick-slide {
    width: 330px !important;
  }
  .group-card-outer {
    width: 314px;
    max-width: 314px;
  }
}
.company-card {
  width: 200px !important;
  max-width: 200px !important;
}
.group-card-outer.selectededgroupcard {
  border: 1px solid var(--Success-Color-Success-600, #079455) !important;
  background: #f3faf6 !important;
}
.group-card-outer {
  display: flex;
  width: 340px;
  height: 180px;
  min-height: 180px;
  max-height: 180px;
  padding: 16px;
  transition: all 0.1s ease-in-out;
  border-radius: 5px;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  background: rgba(255, 255, 255, 0.7);
}

.group-card-outer:hover .gray95014400,
.group-card-outer:hover .upcomingDay,
.group-card-outer:hover .upcomingDate {
  color: #e31b54 !important;
}

.group-card-profilephoto-cover {
  border-radius: 30px;
  border: 1px solid var(--Secondary-Color-Gray-200, #eaecf0);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    rgba(255, 255, 255, 0.2);
}
.studentRegFullHeight {
  min-height: 100vh;
}

.studentRegMarginTop {
  margin-top: 2.5rem;
}
.stepsHeightStudentReg {
  min-height: 15.25rem;
}
.sectionheadingclamped {
  color: #0c111d;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  font-size: clamp(1.25rem, 4vw, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  text-align: start;
}
.StudentRegBackButton {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #101828;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 114.286% */
}
.StudentRegPressEnter {
  color: rgba(227, 27, 84, 0.3);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.StudentRegBackButton:hover {
  color: #e31b54;
  svg path {
    fill: #e31b54;
  }
}
.Name_field {
  background-color: transparent;
  /* font-size:clamp(2rem,4vw,3.5rem) !important; */
  font-size: 3.5rem;
  color: var(--primaryColor);
  outline: none;
  border: none;

  max-width: 100%;
}

.ProgressBarForm {
  position: absolute;
  z-index: 2;
  top: 0;
  height: 5px;
  background-color: var(--primaryColor);
  border-radius: 6px;
  /* // width: 30%; */
}

.Name_field::placeholder {
  color: var(--primaryColor);
  font-size: 32px;
  opacity: 0.2;
}
.agreetoour {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.agreetoour span {
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.sentanemail {
  color: var(--Gray-900, #101828);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.sentanemail span {
  color: var(--Primary-600, #e31b54);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

/* .OTPInput {
    height: 94px;
    min-width: 80px;
    border-radius: 5px;
    border-top: 2px solid #FFF;
    border-left: 2px solid #FFF;
    background: rgba(255, 255, 255, 0.60);
   
   
  
  }
  .Seperator {
    width: 1.875rem;

  } */

@media (max-width: 1024px) {
}
@media (max-width: 768px) {
  .studentRegMarginTop {
    margin-top: 0rem;
  }
  /* .Seperator {
        width: 10px;
    
      }
    .OTPInput{
    height: 80px;
    width: 60px !important;} */

  .Name_field {
    font-size: 2rem;
  }
  .headerBackgroundChange {
    margin-top: 5px;
    background: #fff;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

@media (max-width: 500px) {
  .StudentRegBackground {
    min-height: 100svh;
  }
  .studentRegFullHeight {
    min-height: 100svh;
  }
}
.whitedivrightsideaddtasks {
  border-radius: 5px;
  background: #fff;
  padding: 1.5rem 1.5rem 1.188rem 1.5rem;
}
.black14 {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.generateAiButton {
  display: flex;

  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  background: var(--Base-White, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  background: var(
    --AI,
    linear-gradient(148deg, #444ce7 38.99%, #c01048 80.59%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.black14black {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.gray14new {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.black12 {
  color: #344054;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.previewbutton {
  display: flex;
  width: 111px;
  padding: 8px 12.283px;
  justify-content: center;
  align-items: center;
  gap: 6.142px;
  border-radius: 4px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.6);
}
.previewbutton:hover {
  /* Trans Card: Icons */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.addChallengeDiva {
  width: 250px;
  height: 281px;

  padding: 1.25rem;
  flex-shrink: 0;
  border-radius: 9px;
  border-top: 2px solid var(--Base-White, #fff);
  border-left: 2px solid var(--Base-White, #fff);
  background: var(--Secondary-25, #f2f4f7);
  cursor: pointer;
}
.addChallengeDiva:hover {
  width: 250px;
  height: 281px;

  padding: 1.25rem;
  flex-shrink: 0;
  border-radius: 9px;
  border-top: 2px solid var(--Base-White, #fff);
  border-left: 2px solid var(--Base-White, #fff);
  background: var(--Primary-25, #fff5f6);

  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.addChallengeDiva:hover .svg path {
  fill: #e31b54;
}
.ChipCover {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 0.5rem;
  border-radius: 30px;
  border-top: 2px solid var(--Base-White, #fff);
  border-left: 2px solid var(--Base-White, #fff);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.ChipCover:active {
  transform: scale(0.9);
}
@media (hover: hover) and (pointer: fine) {
  .ChipCover:hover {
    background: var(--primary-50, #fff1f3);
    box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
      0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
      0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
      0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
      0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
      0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
  }
}

.ChipsText {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.ChipsText.chipstextactivated {
  color: var(--Gray-800, #fff);
}
.ChipCover.activated {
  border-color: #fecdd6;
  background: var(--Primary-400, #fd6f8e);
}

@media screen and (max-width: 768px) {
  .ChipCover {
    padding: 10px 5px;
  }
}
.selected_interests_new .interesttext {
  color: #fff;
}
.recruiter-text {
  color: var(--Primary-600, #e31b54);
}
.line-loader {
  display: block;
  --height-of-loader: 4px;
  --loader-color: #e31b54;
  width: 100px;
  height: var(--height-of-loader);
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.2);
  position: relative;
}

.line-loader::before {
  content: "";
  position: absolute;
  background: var(--loader-color);
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-radius: 30px;
  animation: moving 1s ease-in-out infinite;
}

@keyframes moving {
  50% {
    width: 100%;
  }

  100% {
    width: 0;
    right: 0;
    left: unset;
  }
}

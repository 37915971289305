.new-header-button {
  outline: none;
  border: none;
  display: flex;
  min-width: 140px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 12px;

  color: #475467;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  background: transparent;
}
.new-header-button:hover {
  color: #e31b54;
}
.selectedbuttonheader {
  position: relative;
  margin-top: 1px;
  color: #e31b54;
  border-right: 1px solid var(--Secondary-Color-Gray-200, #eaecf0);
  border-left: 1px solid var(--Secondary-Color-Gray-200, #eaecf0);
  background: var(--White, #fff);
  border-bottom: none;
}
.newsecondheader {
  width: 100vw;
  padding: 0.5rem 0rem;

  border-bottom: 1px solid var(--Gray-200, #eaecf0);
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(100px);
}
.gray95014500button {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  color: var(--Secondary-Color-Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  transition: all 0.3s ease-in-out;
}
.gray95014500button:hover {
  color: #e31b54;
  background: var(--Secondary-Color-Gray-Background-Grey, #f2f3f7);
}

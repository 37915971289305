.numbertesttaken {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
}

.vslastmonthtext {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.red {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.red span {
  color: red;
  text-align: center;

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.vslastmonthtext span {
  color: var(--Success-700, #067647);
  text-align: center;

  /* Text sm/Medium */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.popupbuttontext {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

/* .livediv:hover {
  background: var(--Secondary-25, #f5f8ff);
} */

/* .typediv:hover {
  background: var(--Secondary-25, #f5f8ff);
}
  /* Shadow/xs */

.textofthecount {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.gray700 {
  fill: "#344054";
}
.graybody14500 {
  color: var(--Gray-shades-Body, #464c53);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.tableofchallenges .thead .theadtr .theadth {
  padding: 12px 24px;
  align-items: center;
  gap: 12px;

  background: var(--Gray-50, #f9fafb);
  color: #344054;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
}
.tableofchallenges {
  border-radius: 5px;

  background: #fff;

  /* Trans Card: Icons */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
}

.tableofchallenges .tbody .tbodytr .tbodytrtd {
  height: 72px;
  padding: 16px 24px;
  align-items: center;
  flex-shrink: 0;
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}
.hoverredTableChallenge {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}
.hoverredTableChallenge:hover {
  color: #e31b54 !important;
}
.centerdiv {
  display: flex;
  justify-content: center;
}
.filterbutton {
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  color: var(--Gray-700, #344054);

  /* Text sm/Semibold */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.tbodytr {
  border-bottom: 1px solid var(--Gray-200, #eaecf0);
}

.normalbuttons {
  width: 40px;
  height: 40px;
  flex-shrink: 0;

  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 150% */
}
.normalbuttons:hover {
  background: var(--Primary-700, #c01048);

  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}

.tablescroll::-webkit-scrollbar {
  width: 5px;
  height: 8px;

  /* Set the width of the scrollbar */
}

.tablescroll::-webkit-scrollbar-thumb {
  background-color: #d0d5dd;
  border-radius: 10px; /* Set the color of the scrollbar thumb */
}

.tablescroll::-webkit-scrollbar-track {
  background-color: #fff; /* Set the color of the scrollbar track */
}

.tablescrollsidebar::-webkit-scrollbar {
  width: 5px;
}

.tablescrollsidebar::-webkit-scrollbar-thumb {
  background-color: #fff;

  border-radius: 10px; /* Set the color of the scrollbar thumb */
}

.tablescrollsidebar::-webkit-scrollbar-track {
  background-color: #fff;
  /* Set the color of the scrollbar track */
}

.sidebar__inner:hover .tablescrollsidebar::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
}

.testcard {
  background: rgba(255, 255, 255, 0.5);
  border-top: 0ch;
  border-bottom: 0ch;
  border-right: 0ch;
  max-width: 540px;

  border-radius: 6px;
  border-left: 2px solid #fff;
  /* Transp Card v2. */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}

.nameLetter {
  min-height: 90px;

  max-height: 91px;
  min-width: 90px;
  max-width: 90px;
  background-color: #fff5f6;
  border: 5px solid #fff;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;
}
.namecountrya {
  color: #0c111d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
}
.registerationcard {
  overflow: hidden;
  max-width: 540px;
  padding: 1.25rem 1.625rem 2.688rem 2.688rem;
  min-height: 20rem;
  /* max-height: 20.1rem; */
  flex-shrink: 0;
  border-radius: 6px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  background: rgba(255, 255, 255, 0.75);

  /* Trans Card: Icons */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
}
.cardshownames {
  color: var(--Gray-950, #0c111d);

  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 43.2px */
}
.interest_itemaaa {
  display: inline-flex;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: #fff;
}
.studentatnew {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.24rem; /* 25.6px */
}

@media screen and (max-width: 768px) {
  .registerationcard {
    width: 90vw !important;
    max-height: fit-content !important;
  }
}

.nextstepsright {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.507px;
  text-transform: uppercase;
}
.tipscover {
  display: flex;
  width: 220px;
  padding: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 5px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  background: #fff;

  /* Trans Card: Icons */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.tipsinner {
  padding: 12px 8px;

  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
}
.tipstextpopup {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.tipstextpopup span {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.characterslimittext {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.iconshadow {
  border-radius: 10px;
  background: var(--Base-White, #fff);

  /* Trans Card: Icons */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
}
.rightsidesvgcontainer {
  display: flex;
  width: 36px;
  height: 36px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.5);

  /* Trans Card: Icons */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
}
.rightheadingtext {
  color: var(--Primary-600, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.righttext {
  color: var(--Primary-600, #344054);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.tipstext {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.tipsundertext {
  color: var(--Gray-600, var(--Gray-600, #475467));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.tipsundertext span {
  color: var(--Gray-600, var(--Gray-600, #475467));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.explaintask {
  color: var(--Gray-700, var(--Gray-700, #344054));
  /* Sub Header 2/semi bold */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 29px */
}
.question {
  color: var(--Gray-700, var(--Gray-700, #344054));

  /* Sub Header 2/semi bold */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 29px */
}

.answers {
  color: var(--Gray-700, var(--Gray-700, #344054));
  /* Sub Header 2/semi bold */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 29px */
}
.Optional {
  color: var(--Gray-700, var(--Gray-700, #344054));
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.belowOptional {
  color: var(--Gray-600, #475467);

  /* Caption/Caption Text */
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
}
.plusbutton {
  display: flex;
  width: 35px;
  height: 35px;
  padding: 2px 5px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Primary-600, #e31b54);
}
.selectText {
  color: var(--Gray-700, var(--Gray-700, #344054));
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.carousel-container {
  display: flex;
  overflow: hidden;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1.5rem;
}

.parent {
  overflow: hidden;
}

.slider img {
  display: block;
}
.headerbutton {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.9);
  width: 45px;
  height: 45px;
  display: flex;
  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.HeaderText {
  color: #0c111d;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
}

.wheredoyou {
  color: var(--171721-primary, #171721);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px; /* 135% */
}
.fastTrack {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 32.4px */
}
.fastTracka {
  filter: drop-shadow(0px 2.191px 1.753px rgba(0, 49, 122, 0.02))
    drop-shadow(0px 5.265px 4.212px rgba(0, 49, 122, 0.03))
    drop-shadow(0px 9.913px 7.93px rgba(0, 49, 122, 0.04))
    drop-shadow(0px 17.683px 14.146px rgba(0, 49, 122, 0.04))
    drop-shadow(0px 33.074px 26.459px rgba(0, 49, 122, 0.05))
    drop-shadow(0px 79.167px 63.333px rgba(0, 49, 122, 0.07));
}
.belowfast {
  color: var(--Gray-600, #475467);

  /* Body Regular/Regular */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.nextone {
  color: var(--Secondary-700, #3538cd);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
.seeall {
  color: var(--Primary-600, #e31b54);

  /* text button */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 23.2px */
}
.main-card-container {
  position: relative;

  height: 373px; /* Initial height */
  margin-right: 10px; /* Adjust the margin as needed */
  transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out;
}

.main-card-container:hover {
  transform: scale(1.2); /* Adjust the scale factor as needed */
  z-index: 1;
}

/* Add a wrapper to prevent the cards from stacking vertically */
.card-wrapper {
  display: flex;
}

/* Optional: Adjust the styling for better layout */
.grey-card-container {
  border: 1px solid #ccc;
}
.descriptiontask {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.userimage {
  border-radius: 180px;
}

.name {
  color: var(--Gray-950, #0c111d);
  font-feature-settings: "clig" off, "liga" off;

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.uni {
  color: var(--Gray-700, #344054);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}
.buttons {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
}
.tools {
  color: var(--Gray-400, var(--Gray-400, #98a2b3));
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.507px;
  text-transform: uppercase;
}
.shadowa {
  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.backgrounddashboard {
  background: url(https://varyance.b-cdn.net/uploads/newregisterbackground.png);
  background-repeat: no-repeat;
  background-size: cover;

  margin: 0;
  min-height: 100vh;
}

.ChipCover {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 0.5rem;
  border-radius: 30px;
  border-top: 2px solid var(--Base-White, #fff);
  border-left: 2px solid var(--Base-White, #fff);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.ChipCover:active {
  transform: scale(0.9);
}
@media (hover: hover) and (pointer: fine) {
  .ChipCover:hover {
    background: var(--primary-50, #fff1f3);
    box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
      0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
      0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
      0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
      0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
      0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
  }
}

.ChipsText {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.ChipsText.chipstextactivated {
  color: var(--Gray-800, #fff);
}
.ChipCover.activated {
  border-color: #fecdd6;
  background: var(--Primary-400, #fd6f8e);
}

@media screen and (max-width: 768px) {
  .ChipCover {
    padding: 10px 5px;
  }
}

.formtext {
  color: #344054;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.tagsareused {
  color: #e31b54;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.forminput {
  display: flex;
  padding: 12px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  transition: all 0.3s ease-in-out;
}

.forminput::placeholder {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.forminput:hover {
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.forminput:focus {
  outline: none;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.forminput.success {
  border-radius: 8px;
  border: 1px solid var(--Success-600, #079455);
  background: var(--Base-White, #fff);
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.forminput.error {
  border-radius: 8px;
  border: 1px solid var(--Error-400, #f97066);
  background: var(--Base-White, #fff);
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.options {
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.drop-area {
  display: flex;

  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  text-align: center;
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--Base-White, #fff);
}

.drop-area img {
  margin-top: 10px;
}

.tag-container {
  gap: 10px;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
}

.tags {
  display: flex;

  padding: 9px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: linear-gradient(0deg, #fff 0%, #fff 100%),
    rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.selected {
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: linear-gradient(
      0deg,
      var(--Primary-300, #fea3b4) 0%,
      var(--Primary-300, #fea3b4) 100%
    ),
    rgba(255, 255, 255, 0.2);
}
.tags img {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.itemstexts {
  color: var(--gray-500-p, var(--Gray-500, #667085));
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.checkbox {
  width: 18px;
  height: 18px;
}

.backbutton {
  display: inline-flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: var(--Gray-600, #475467);
  color: #475467;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.newjoinbuttonhover {
  display: inline-flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  /* border: 2px solid var(--Gray-300, #aaadb3); */
  border: none;
  background: #fff;
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.newjoinbuttonhover:hover {
  color: #e31b54;
  border: 0.75px solid var(--Gray-300, #d0d5dd);

  box-shadow: 0px 0.75px 1.5px 0px rgba(16, 24, 40, 0.05);
}
.backbutton:hover {
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}

.continuebutton {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  /* border: 1px solid var(--Gray-300, #d0d5dd); */
  border: none;
  background: var(--Primary-600, #e31b54);
  color: var(--Snow, #fff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.continuebuttonsmaller {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Primary-600, #e31b54);
  color: var(--Snow, #fff);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.continuebuttonsmaller:hover {
  background: var(--Primary-700, #c01048);

  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}

.continuebuttonsmaller:disabled {
  border-radius: 6px;
  background: var(--Primary-300, #fea3b4);
}

.continuebutton:hover {
  background: var(--Primary-700, #c01048);

  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.continuebutton:disabled {
  border-radius: 6px;
  background: var(--Primary-300, #f0cad1);
}
.companyCardtoDisplay {
  width: 80%;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 6px;
  background: rgba(255, 255, 255, 1);
  position: sticky;
  top: 20%;
}
.custom-image {
  position: relative;

  max-width: 78px;
  height: 78px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border-radius: 5px;
  transform: rotate(-0.219896deg);
  filter: drop-shadow(2.94359px 2.82954px 1.17744px rgba(0, 0, 0, 0.07))
    drop-shadow(7.07386px 5.32778px 2.82954px rgba(0, 0, 0, 0.035))
    drop-shadow(13.3194px 9.50385px 5.32778px rgba(0, 0, 0, 0.0282725))
    drop-shadow(23.7596px 17.7759px 9.50385px rgba(0, 0, 0, 0.0196802))
    drop-shadow(44.4398px 42.5489px 17.7759px rgba(0, 0, 0, 0.01));
}

.companyname {
  color: var(--Gray-900, #101828);

  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
}
.tagline {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 25.6px */
}
.CreateProfileCompanyName {
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
  max-width: 90%;
}

.tagscard {
  display: flex;
  padding: 4px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: var(--Primary-25, #fea3b4);
}
.selectedcard {
  color: var(--gray-500-p, var(--Gray-500, #667085));
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.pricing-table tbody tr td {
  padding: 16px 25px;
}
.pricing-table {
  border: 1px solid #eaecf0;
  border-radius: 5px;
}
.pricing-table tbody tr:nth-child(odd) {
  background: #fcfcfd;
}

.pricing-table tbody tr:nth-child(even) {
  background: #ffffff; /* Optional: Set a different background for even rows */
}

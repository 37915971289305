/* @tailwind base;
@tailwind components;
@tailwind utilities; */
@font-face {
  font-family: "FilmotypeFashion";
  src: url("./webfonts/font.woff2") format("woff2");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

html {
  scroll-snap-type: y mandatory;

  /* Add any other styles */
}
.buttonblack12 {
  color: #101828;
  text-align: start;
  background: transparent;
  border: none;
  padding: 0;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 22.4px */
}
.buttonblack12:hover {
  color: #e31b54;
}
.gray12 {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
}
.hoverredsvg {
  stroke: #292d32;
  transition: all 0.3s ease-in-out;
}
.hoverredsvg:hover {
  stroke: #e31b54 !important;
}
.globalhoverred:hover {
  color: #e31b54;
}
.Toastify * {
  font-family: Poppins;
  font-size: 1rem;
  color: "#101828";
}
.center {
  display: flex;
  justify-content: center;
}
.space {
  display: flex;
  justify-content: space-between;
}

.interest_Item {
  display: flex;
  padding: 0.625rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 1.875rem;
  border-top: 2px solid var(--Base-White, #fff);
  border-left: 2px solid var(--Base-White, #fff);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.interest_Item.active {
  color: #fff;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 8px;
  border-radius: 30px;
  border-color: #fecdd6;
  background: var(--Primary-400, #fd6f8e);
  /* Trans Card: Icons */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
}

.interest_Item.active:hover {
  background: var(--primary-300, #fea3b4);
  box-shadow: none;
}

.interest_Item:hover {
  background: var(--primary-50, #fff1f3);

  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.datetime-wrapper {
  position: relative;
  width: 100%;
}

.datetime-wrapper .newinputs {
  padding-right: 2rem; /* Add spacing for the icon */
}

.datetime-wrapper .calendar-icon {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  transform: translateY(-50%);
  pointer-events: none; /* Icon won't block input interaction */
}
.interest_Item .Interest_text {
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
}

.interest_Item p {
  text-align: center;
  margin: auto auto;
}

.interest_Item .interest_icon {
  height: 24px;
  width: 24px;
}

.marginsleftrightCompany {
  margin-left: 6.25rem;
  margin-right: 6.25rem;
}
.paddingleftrightnew {
  margin-left: 6.25rem;
  margin-right: 6.25rem;
}
@media (max-width: 1024px) {
  .marginsleftrightCompany {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }
  .paddingleftrightnew {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .interest_Item:hover {
    background: none;

    box-shadow: none;
  }
}
.paddingAdjust {
  padding: 1.5rem;
}
@media (max-width: 768px) {
  .marginsleftrightCompany {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .paddingAdjust {
    padding: 1.25rem;
  }
  .paddingleftrightnew {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
}
@media (max-width: 500px) {
  .marginsStudent {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .marginsleftrightCompany {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

@media (max-width: 450px) {
  .marginleft20 {
    margin-left: 1.25rem;
  }
  .paddingleftrightnew {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
}

.snap-section {
  scroll-snap-align: start;
  /* Add any other styles */
}
.shadowa {
  /* Trans Card v2 */
  /* blue */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.hovershadowa {
  transition: all 0.3s ease-in-out;
}
.hovershadowa:hover {
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.testingshadow {
  /* Trans Card v2 */
  /* blue */
  box-shadow: inset 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    inset 0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    inset 0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    inset 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
.leftshadow {
  box-shadow: 0px 0px 10pxpx 0px rgba(0, 0, 0, 0.75) inset;
  -webkit-box-shadow: 3px 0px 10px 0px rgba(0, 0, 0, 0.05) inset;
}

.lightshadow {
  /* blue */
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}
/* .ql-container {
  min-height: 300px;
} */
.selected_interests_new {
  border-radius: 30px;
  border: 1px solid var(--Primary-500, #f63d68) !important;
  background: var(--Primary-600, #e31b54) !important;
}

.selected_interests_new:hover {
  background: var(--Primary-600, #e31b54);
}

.ql-editor {
  min-height: 300px;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.smallshadow {
  /* Trans Card: Icons */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
}
.transition * {
  transition: all 0.3s ease-in-out;
}

.newinputs {
  display: flex;
  padding: 12px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  transition: all 0.3s ease-in-out;
}

.newinputs::placeholder {
  color: var(--Gray-400, #98a2b3);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.newinputs:hover {
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.newinputs:focus {
  outline: none;
  border-radius: 8px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.newinputs.success {
  border-radius: 8px;
  border: 1px solid var(--Success-600, #079455);
  background: var(--Base-White, #fff);
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

.newinputs.error {
  border-radius: 8px;
  border: 1px solid var(--Error-400, #f97066);
  background: var(--Base-White, #fff);
  box-shadow: 0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02);
}

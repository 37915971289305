.headerhomepage {
  position: sticky;
  z-index: 20;
  top: 0;
  background: #fff;
  display: flex;
  width: 100%;
  padding: 0.5rem 6.25rem;
  justify-content: space-between;
  align-items: center;
}
.main-heading {
  color: var(--Secondary-Color-Gray-950, #0c111d);
  font-size: clamp(32px, 4vw, 36px);
  font-style: normal;
  font-weight: 400;
  max-width: 411px;
  text-align: center;
  line-height: clamp(40px, 2vw, 44px);
}

.marquee-container {
  position: relative;
  height: 460px;
  overflow: hidden;
  width: 100%;
}
.gray60016400 {
  color: var(--Secondary-Color-Gray-600, #475467);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.marquee2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 0;
  gap: 0.75rem;
  animation: scrollDown 13s linear infinite;
}
.marquee {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 0;
  gap: 0.75rem;
  animation: scrollUp 13s linear infinite;
}

@keyframes scrollUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-50%);
  }
}
@keyframes scrollDown {
  0% {
    transform: translateY(-50%);
  }
  100% {
    transform: translateY(0%);
  }
}
.newHomeThird {
  padding: 3.75rem 0rem;
  background: #fff;
}
.newHomeSecond {
  background: #f2f3f7;
  padding: 8.125rem 0rem;
}
.newHomeSlider-container {
  padding: 100px 0px 100px 0px;
  background: rgba(242, 244, 247, 0.8);
}
.headerhomepage {
  transition: opacity 0.5s ease; /* Smooth transition for opacity */
}

.headerhomepage.hidden {
  opacity: 0; /* Hide the element when hidden */
  pointer-events: none; /* Disable pointer events when hidden */
}

.fourthsectionhome {
  background-image: url(https://varyance.b-cdn.net/uploads/homeforth.png);
  background-size: cover; /* Cover the entire width while maintaining aspect ratio */
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 40.188rem;
  max-height: 40.12rem;
}
.forthsectionmarginTop {
  margin-top: 3rem;
}
.forthsectionpadding {
  margin-top: 11.125rem;
  padding-top: 5.375rem;
}
.welcomebackbutton {
  display: flex;
  padding: 0.7rem 1.25rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  border-radius: 0.375rem;
  border: none;
  background: var(--Base-White, #fff);

  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}

.welcomebackbutton:hover {
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);

  background: #fcfcfd;
}
.popuptext {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.popuptextcover:hover .popuptext {
  color: var(--Gray-950, #0c111d);
}
.filmo20 {
  color: var(--Gray-900, #101828);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 150% */
  max-width: 50%;
}
.TYPEOFCHALLENGEbelow {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.TYPEOFCHALLENGEbelow span {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.innerglass {
  padding: 12px;

  gap: 24px;
  align-self: stretch;
  border-radius: 5px;
  background: #fff;
}
.popuptextcover:hover svg path {
  fill: var(--Gray-950, #0c111d);
}
.headersmalltext {
  color: var(--Gray-800, #1d2939);
  /* Caption/Caption Text */
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 20.3px */
}
.filmo30400 {
  color: var(--Gray-950, #0c111d);

  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px; /* 140% */
}
.filmo16 {
  color: var(--Gray-950, #0c111d);

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.popuptext:hover {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.welcomemenucontainer {
  display: flex;
  align-items: center;
  gap: 1.25rem;
}
.backtosearch {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.maybeLater {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 18px */
}
.menubuttonhomepage {
  display: flex;
  padding: 0px 1.25rem;
  background-color: #fff;
  align-items: center;
  gap: 0.625rem;
  border: none;
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
}
.headerlogohome {
  width: 3rem;
  height: 3rem;
  fill: var(
    linear-gradient(136deg, #c01048 -4.38%, #a11043 67.26%, #89123e 102.63%)
  );
  filter: drop-shadow(0px 0.23px 0.184px rgba(0, 49, 122, 0.02))
    drop-shadow(0px 0.552px 0.442px rgba(0, 49, 122, 0.03))
    drop-shadow(0px 1.04px 0.832px rgba(0, 49, 122, 0.04))
    drop-shadow(0px 1.856px 1.485px rgba(0, 49, 122, 0.04))
    drop-shadow(0px 3.471px 2.777px rgba(0, 49, 122, 0.05))
    drop-shadow(0px 8.308px 6.646px rgba(0, 49, 122, 0.07));
}

.fasttrackyour {
  color: var(#344054);
  font-family: Poppins;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 52.2px */
}
.paddingslefttop {
  margin-top: 14.875rem;
  margin-left: 7rem;
  margin-right: 7rem;
  background: transparent;
}
.paddingleftright {
  margin-left: 7rem;
  margin-right: 7rem;
}
.secondsecPad {
  margin-left: 7rem;
  /* padding-right: 7rem; */
}
.studentslidercard1:hover .slidesheading {
  color: #e31b54;
}
.studentslidercard1 {
  background-repeat: no-repeat;
  background-position: right;

  border-radius: 12px;
  background: #fff;
  /* background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0.12%,
      rgba(0, 0, 0, 0.5) 83.7%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
    url(https://varyance.b-cdn.net/uploads/solutionsecond.png) lightgray 2.795px -36.806px / 100%
      137.849% no-repeat; */
  background-size: cover;
  width: 100%;
  max-width: 800px;
  min-width: 800px;
  min-height: 400px;
  max-height: 400px; /* Or any maximum width you want */
  aspect-ratio: 800 / 400;
  height: auto; /* Maintain the aspect ratio */
  transition: background-size 0.3s ease-in-out;
}
.studentslidercard2 {
  /* background: url(https://varyance.b-cdn.net/uploads/newConnect.svg); */
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  border-radius: 12px;
  background: #fff;
  background-position: bottom;
  width: 100%;
  max-width: 800px;
  min-width: 800px;
  min-height: 400px;
  max-height: 400px; /* Or any maximum width you want */
  aspect-ratio: 800 / 400;
  height: auto; /* Maintain the aspect ratio */
  transition: background-size 0.3s ease-in-out;
}
.studentslidercard2:hover .slidesheading2 {
  background: none; /* solid color on hover */
  -webkit-text-fill-color: #e31b54;
}
.studentslidercard3 {
  background-repeat: no-repeat;
  background-position: right;

  border-radius: 12px;

  background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 0.12%,
      rgba(0, 0, 0, 0.5) 83.7%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
    url(https://varyance.b-cdn.net/uploads/solutionthird.png) lightgray 2.795px -36.806px /
      100% 137.849% no-repeat;
  background-size: cover;
  width: 100%;
  max-width: 800px;
  min-width: 800px;
  min-height: 400px;
  max-height: 400px; /* Or any maximum width you want */
  aspect-ratio: 800 / 400;
  height: auto; /* Maintain the aspect ratio */
  transition: background-size 0.3s ease-in-out;
}
.studentslidercard4 {
  background: #fff;
  background-repeat: no-repeat;
  background-position: right;

  border-radius: 12px;

  background-size: cover;
  width: 100%;
  max-width: 800px;
  min-width: 800px;
  min-height: 400px;
  max-height: 400px; /* Or any maximum width you want */
  aspect-ratio: 800 / 400;
  height: auto; /* Maintain the aspect ratio */
  transition: background-size 0.3s ease-in-out;
}
.studentslidercard4:hover .slidesheading {
  color: #e31b54;
}

.dsadasasd .slick-list {
  padding: 0rem !important;

  min-width: 820px;
  height: 400px;
}

.carreerstartby {
  color: var(--Gray-900, #101828);

  font-size: 5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.carreerstartby span {
  line-height: 120%;
}

.secondtextuni {
  margin-top: 1.625rem;
}
.unilistinputdiv {
  display: flex;
  width: 23.938rem;
  padding: 4px 14px;
  align-items: center;

  gap: 0.5rem;

  min-height: 48px;
  max-height: 48px;

  flex-shrink: 0;
  border-radius: 180px;
  border: 1px solid #d0d5dd;
  background: #fff;
}
.notselecteduni {
  background-color: #e31b54;
}
.uniselect {
  display: flex;
  align-items: center;
  gap: 0.875rem;
  flex: 1 0 0;
}

.widthsettext {
  max-width: 64.375rem;
}

.unilists {
  cursor: pointer;
}

.slidesheading {
  color: var(--Gray-950, #0c111d);

  font-size: clamp(16px, 4vw, 24px);
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  transition: color 0.3s ease-in-out;
}
.slidesheading2 {
  background: var(
    --All-Gradients-Diversity-Gradient,
    linear-gradient(90deg, #2d31a6 0%, #e31b54 25%, #f38a23 75%)
  );
  background-clip: text;
  font-size: clamp(16px, 4vw, 24px);
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  transition: all 0.3s ease-in-out;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.white16 {
  color: var(--Base-White, #fff);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 150% */
}

.uniselecttext {
  color: var(--Gray-900, #101828);
  width: 100%;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;

  line-height: 24px; /* 150% */
}

.showUnilistmodal {
  width: 23.938rem;
  max-height: 37.438rem;

  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);

  border-radius: 20px;
  border: 1px solid var(--Secondary-50, #eef4ff);
  background: #fff;
}
.paddingsinsearchlist {
  padding-top: 1.625rem;
  padding-left: 1.063rem;
}

.searchinput {
  display: flex;

  padding-left: 9px;
  align-items: center;
  gap: 1.25rem;

  color: var(--Gray-600, #475467);

  /* Text md/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.newHomeBackground {
  background: url(https://varyance.b-cdn.net/uploads/newHomeBackground.svg);
  background-repeat: no-repeat;
  background-size: auto 100%; /* Stretch only the width, keep height auto */
  background-position: center; /* Center the background image */
}

input[type="search"]::-webkit-search-clear-button {
  -webkit-appearance: none;
  appearance: none;
  color: black; /* Sets the color of the clear button */
}

.searchinputuni {
  border: none;
  border-bottom: 2px solid black; /* Adding bottom border */
  transition: border-bottom-color 0.2s ease; /* Adding transition for smooth effect */
  color: #475467;

  font-family: "Poppins";

  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border: none;
}

.searchinputuni:focus {
  border-bottom-color: transparent;
  outline: none;
}

.bottomsearchlist {
  background: #f5f8ff;
  width: 100%;
  flex-shrink: 0;
  padding: 10px;
}
.countrydisplay {
  display: inline-flex;
  padding: 0.375rem 0.5rem;
  align-items: center;
  gap: 0.375rem;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: #fcfcfd;
  color: #667085;
  font-family: "Poppins";
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.changelocationbutton {
  color: var(--Primary-600, #e31b54);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 17.4px */
}

.belowuniselect {
  margin-top: 4.125rem;
}
.personimage {
  width: 3.75rem;
  height: 3.75rem;
}

.imgshadow {
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}

.imgsunderuni {
  display: flex;
  align-items: center;
}

.move {
  position: relative;
}

.nexttopics {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem; /* 133.333% */
  max-width: 51.75rem;
}
.nexttopics span {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2rem;
}

.SecondSection {
  margin-left: 1.87rem;
}

.card1secondsec {
  display: flex;
  width: 33.813rem;

  padding: 1.5rem 1.5rem 0px 1.5rem;
  flex-direction: column;
  /* justify-content: flex-end; */
  align-items: center;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;

  /* Trans Card v2 */
}
.card1challenge {
  display: flex;
  padding: 0.625rem 0.875rem;
  justify-content: center;
  align-items: center;
  gap: 10.892px;
  border-radius: 100px;
  border: 2px solid #e31b54;
  color: var(--Primary-600, #e31b54);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.firstcardvirttext {
  color: #e31b54;
  margin-top: 1.875rem;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem; /* 114.286% */
}

.textundervitual {
  margin-top: 0.75rem;
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 155.556% */
}

.textundervitual span {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}

.HomeThirdSection {
  margin-top: 12.5rem;
}
.caseStudy {
  color: #0c111d;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem; /* 155.556% */
}
.undercaseStudy {
  color: #667085;
  margin-top: 1.25rem;
  font-size: clamp(1.5rem, 4vw, 2.25rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(2rem, 4vw, 3.806rem); /* 60.9px */
}
.undercase1 {
  color: #6172f3;
  font-size: clamp(1.5rem, 4vw, 2.25rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(2rem, 4vw, 3.806rem);
}
.undercase2 {
  color: #0c111d;

  font-size: clamp(1.5rem, 4vw, 2.25rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(2rem, 4vw, 3.806rem);
}
.undercase3 {
  color: #e31b54;

  font-size: clamp(1.5rem, 4vw, 2.25rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(2rem, 4vw, 3.806rem);
}
.widthfirst {
  width: 50%;
}
.widthsecond {
  width: 50%;
  min-width: 335px;
}
.marginfromabove {
  margin-top: 1.25rem;
}
.getpoints {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem; /* 180% */
}

.newskillwrapper {
  display: flex;
  height: 32px;
  padding: 6px 14px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: linear-gradient(
      0deg,
      var(--Base-White, #fff) 0%,
      var(--Base-White, #fff) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
.skillswrapper {
  display: flex;

  padding: 0.375rem 0.937rem;
  align-items: center;
  gap: 0.75rem;
  flex-shrink: 0;
  border-radius: 30px;
  border: 0.75px solid #eff0f1;
  background: linear-gradient(
      0deg,
      var(--Secondary-25, #f5f8ff) 0%,
      var(--Secondary-25, #f5f8ff) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: #0c111d;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.063rem; /* 149.934% */
}
.skillswrapper span {
  color: #e31b54;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 17.992px;
}

.exemplary {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.lastSection {
  margin-top: 8.125rem;
}
.startjourney {
  color: #12141d;
  max-width: 40.188rem;
  font-size: 2.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.5rem; /* 111.111% */
  letter-spacing: -1.2px;
}

.horiline {
  margin-top: 5rem;
}
.marginfromlinetop {
  margin-top: 4.375rem;
  margin-bottom: 3rem;
}

.footer1st {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 19.75rem;
  gap: 2rem;
}
.varyance {
  color: #c01048;

  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem; /* 133.333% */
}

.Accelerate {
  margin-top: 0.75rem;
  color: #000;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}
.footersecond {
  gap: 3.75rem;
}
.footerseconddiv {
  width: 11rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
  flex: 1 0 0;
}
.secondfirsttext {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
  letter-spacing: 0.507px;
  text-transform: uppercase;
}
.megazineimg {
  width: 1.75rem;
  height: 1.75rem;
}
.secondsametexts {
  color: #000;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  cursor: pointer;
  font-weight: 500;
  line-height: 1.75rem; /* 155.556% */
}
.secondsametexts:hover {
  color: #a11043;
}
.ltd {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
  cursor: pointer;
}

.ltd:hover {
  color: #a11043;
}

.secondsecseconddiv {
  background: url(https://varyance.b-cdn.net/uploads/newConnect.svg);
  min-width: 960px;
  min-height: 520px !important;
  max-height: 521px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.seconddivtop {
  display: flex;
  padding: 8.169px 10px;
  justify-content: center;
  align-items: center;
  gap: 10.892px;
  border-radius: 100px;
  border: 2px solid var(--Base-White, #fff);
  color: var(--Base-White, #fff);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}
.latestupdate {
  color: var(--Base-White, #fff);

  font-size: 1.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2rem; /* 114.286% */
}
.belowlatest {
  width: 46.25rem;
  color: #fff;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 155.556% */
}
.gotaquestion {
  color: rgba(255, 255, 255, 0.6);

  font-size: 3.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 4.5rem; /* 120% */
}
.messagecampus {
  color: rgba(255, 255, 255, 0.6);
  font-family: Poppins;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 140% */
}
.secondsecthirddiv {
  position: relative; /* Ensure relative positioning for the pseudo-element */
  padding: 1.5rem;
  width: 26.875rem;
  flex-shrink: 0;
  background: url(https://varyance.b-cdn.net/uploads/boxgradient.png);
}

.secondsecthirddiv::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    255,
    255,
    255,
    0.75
  ); /* White background with 75% opacity */
}

.cardsmargintop {
  margin-top: 3.313rem;
}
.sliderthirdcards {
  min-width: 20.375rem;
  min-height: 20.75rem;
  padding: 0.625rem;

  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05);
}
.bluedivs {
  min-width: 19.125rem;
  min-height: 16.75rem;
  padding: 14px 30px 24px 12px;
  flex-shrink: 0;
  border-radius: 0.482rem;
  background: #eef4ff;
}

.daysago {
  color: #0c111d;
  text-align: right;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 166.667% */
}
.underdaysago {
  color: #f63d68;
  text-align: right;
  font-family: Poppins;
  font-size: 0.75rem;

  margin-top: "0.875rem";
  font-weight: 600;
  line-height: 1rem; /* 133.333% */
}
.undercompanyname {
  color: #171721;

  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 155.556% */
}
.internshipcardhome {
  display: inline-flex;
  height: 1.5rem;
  padding: 0.25rem 0.5rem;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0.625rem;
  flex-shrink: 0;
  border-radius: 24.796px;
  border: 0.827px solid #eff0f1;
  background: #f5f8ff;
  color: #667085;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */
}
.pinkdivs {
  min-width: 19.125rem;
  min-height: 16.75rem;
  padding: 14px 30px 24px 12px;
  flex-shrink: 0;
  border-radius: 0.482rem;
  background: #fff1f3;
}
.findinternships {
  color: #000;
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 155.556% */
}
.findinternships span {
  color: var(--Base-Black, #000);
  font-family: Poppins;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.75rem;
}
.HOMEPAGESLIDERTESTING .slick-list {
  min-height: 650px !important;
}

.HOMEPAGESLIDERTESTING .slick-track {
  min-height: 660px;
  overflow: visible;
}

.HOMEPAGESLIDERTESTING .slick-slider {
  margin-top: 105px;
  height: 600px;
}
.HOMEPAGESLIDERTESTING .slick-dots {
  display: none !important;
}
.HOMEPAGESLIDERTESTING .slick-dots * {
  background: transparent !important;
}
.centered-slide {
  display: flex !important;
  justify-content: center !important;
  height: 100%;
}
.snapscroll {
  overflow: "visible";
}
/* .snapscroll {
    scroll-snap-type: y mandatory; 
    scroll-behavior: smooth; 
  } 
  
 .section {
    scroll-snap-align: start; 
  }
  
  .sectioncenter {
    scroll-snap-align: center; 
   } */

.hor-scroll {
  position: relative;
  height: 350vh;

  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: clip;
}

.hor-scroll-con {
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* justify-content: space-around; */
  overflow: hidden;
  padding: 4%;
}
.scrolldowntoexplore {
  color: #667085;
  text-align: center;

  font-size: 2.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 60.9px */
}
.span1 {
  color: #0c111d;

  font-size: 2.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.span2 {
  color: #6172f3;

  font-size: 2.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}
.span3 {
  color: #e31b54;
  font-size: 2.625rem;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
}

.getinspired {
  color: #667085;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
}
.getinspired span {
  color: #0c111d;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
}
.itsearly {
  margin-top: 1.25rem;
  width: 43.5rem;
  color: var(--Gray-950, #0c111d);
  font-size: clamp(1.5rem, 4vw, 1.875rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(2rem, 4vw, 42px);
}
.greyspan {
  color: var(--Gray-700, #344054);

  font-size: clamp(1.5rem, 4vw, 1.875rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(2rem, 4vw, 42px);
}
.bluespan {
  color: #6172f3;

  font-size: clamp(1.5rem, 4vw, 1.875rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(2rem, 4vw, 42px);
}
.pinkspan {
  color: #e31b54;
  font-size: clamp(1.5rem, 4vw, 1.875rem);
  font-style: normal;
  font-weight: 400;
  line-height: clamp(2rem, 4vw, 42px);
}
.blur {
  filter: blur(100%);
}
.wrapperofinterests {
  margin-top: 2.5rem;
  gap: 2rem 1.125rem;
  width: 100%;
}
.interestcont {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 0.5rem;
  border-radius: 30px;
  border-top: 2px solid var(--Base-White, #fff);
  border-left: 2px solid var(--Base-White, #fff);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0.7) 100%
    ),
    rgba(255, 255, 255, 0.2);
  color: var(--Gray-800, #1d2939);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.active {
  border-color: #fecdd6;
  background: var(--Primary-400, #fd6f8e);
  /* Trans Card: Icons */
}
.interesttext {
  color: #1d2939;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
}
.selected_interests_new .interesttext {
  color: #fff;
}

.homegrad {
  background: url(https://varyance.b-cdn.net/uploads/homegrad.png);
  background-repeat: no-repeat;

  background-size: cover;
}
.learnmoreabout {
  color: var(--Gray-950, #0c111d);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}
.learnmoreabout span {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.learnmorewidth {
  margin-top: 3rem;

  width: 43.75rem;
}
.startonboard {
  margin-top: 2rem;
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.startonboard:hover {
  color: var(--Primary-600, #a11043);
}
.card-outer {
  width: 335px;
  height: 377px;
  flex-shrink: 0;
  border-radius: 9.328px;
  background: #fff;
  padding: 12px 11px;
  /* Transp Card v2. */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.card-center-background {
  width: 313px;
  height: 166px;
  flex-shrink: 0;
  padding: 1rem;
  border-radius: 6px 6px 0px 0px;
  background: url(https://varyance.b-cdn.net/uploads/cardbackground.png);
  background-size: cover;
}
.blue-div {
  width: 312px;
  height: 297px;
  flex-shrink: 0;
  border-radius: 6px;
  background: #f5f8ff;
}
.logoimg-container {
  filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.1));
}
.tasks90 {
  color: #98a2b3;
  text-align: center;
  font-family: Poppins;
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
}
.financialmodelling {
  width: 272px;
  color: var(--171721-primary, #171721);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 150% */
}

.clouds {
  align-self: stretch;
  color: #e13551;
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
}
/* .testtext{
    font-size: clamp(30px,2vw,80px);
} */
.overflowx {
  overflow-x: clip;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #e31b54; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.studenthomeVideo {
  background: url(../../assets/videos/Varyance.mp4);
  background-repeat: no-repeat;
  background-size: cover;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1440px) {
  .forthsectionpadding {
    margin-top: 4.125rem;
    padding-top: 5.375rem;
  }
  .fourthsectionhome {
    width: 100%;
    min-height: 38rem;
    max-height: 38rem;
  }

  .findinternships {
    font-size: 0.875rem;
  }
  .findinternships > span {
    font-size: 0.875rem;
  }
  .latestupdate {
    font-size: 1.125rem;
  }
  .seconddivtop {
    font-size: 0.875rem;
  }
  .textundervitual {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  .textundervitual span {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  .menubuttonhomepage {
    font-size: 0.875rem;
  }
  .headerlogohome {
    height: 2rem;
    width: 2rem;
  }
  .underdaysago {
    margin-top: 0.75rem;
  }
  .firstcardvirttext {
    margin-top: 0.75rem;
    font-size: 1.125rem;
    line-height: 1.75rem; /* 114.286% */
  }
  .sliderthirdcards {
    min-width: 15.188rem;
    max-width: 15.12rem;
    min-height: 15.2rem;
    max-height: 15.2rem;
    padding: 0.625rem;

    border-radius: 5px;
    background: #fff;
    box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
      0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
      0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
      0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
      0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05);
  }
  .bluedivs {
    min-width: 13.813rem;
    min-height: 11.938rem;
    padding: 11px 11px 16px 11px;
    flex-shrink: 0;
    border-radius: 0.482rem;
    background: #eef4ff;
  }
  .pinkdivs {
    min-width: 15.813rem;
    min-height: 13.938rem;
    padding: 11px 11px 16px 11px;
    flex-shrink: 0;
    border-radius: 0.482rem;
  }
  .cardsmargintop {
    margin-top: 1rem;
  }
  .card1challenge {
    display: flex;
    padding: 6px 14px;
    justify-content: center;
    font-size: 0.875rem;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    border: 2px solid var(--Primary-600, #e31b54);
  }

  .belowlatest {
    width: auto;
    font-size: 14px;
  }
  .secondsecthirddiv {
    max-height: 32.5rem;
  }

  .paddingslefttop {
    margin-top: 10.78125rem; /* 14.375 * 0.75 */
    margin-left: 4.6875rem; /* 6.25 * 0.75 */
    margin-right: 4.6875rem; /* 6.25 * 0.75 */
  }
  .paddingleftright {
    margin-left: 4.6875rem; /* 6.25 * 0.75 */
    margin-right: 4.6875rem;
  }
  .secondsecPad {
    margin-top: 10.78125rem; /* 14.375 * 0.75 */
    margin-left: 4.6875rem; /* 6.25 * 0.75 */
  }
  .fasttrackyour {
    color: var(--Gray-950, #0c111d);
    font-family: Poppins;
    font-size: 1.125rem; /* 1.5 * 0.75 */
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 2 * 0.75 */
  }
  .secondtextuni {
    margin-top: 2.016rem; /* 2.688 * 0.75 */
  }
  .carreerstartby {
    font-size: 3.375rem; /* 4.5 * 0.75 */
    line-height: 3.8rem; /* 4.5 * 0.75 */
  }
  .widthsettext {
    max-width: 43.4535rem; /* 57.938 * 0.75 */
  }

  .belowuniselect {
    margin-top: 2.34375rem; /* 3.125 * 0.75 */
  }
  .personimage {
    width: 2.625rem; /* 3.5 * 0.75 */
    height: 2.625rem; /* 3.5 * 0.75 */
  }
  .nexttopics {
    max-width: 31.92225rem; /* 42.563 * 0.75 */
    font-size: 0.9375rem; /* 1.25 * 0.75 */
    line-height: 1.3125rem; /* 1.75 * 0.75 */
  }
  .nexttopics span {
    font-size: 0.9375rem; /* 1.25 * 0.75 */
    line-height: 1.3125rem; /* 1.75 * 0.75 */
  }
  .SecondSection {
    margin-top: 4.6875rem; /* 6.25 * 0.75 */
  }
  .card1secondsec {
    width: 20.25rem;
    padding: 1.5rem 1.5rem 0rem 1.5rem;

    max-height: 32.5rem;
  }
  .secondsecthirddiv {
    width: 20.25rem;
    padding: 1.5rem 1.5rem 0rem 1.5rem;

    max-height: 32.5rem;
  }

  .guidelineimg {
    max-width: 20.174rem;
  }
  .HomeThirdSection {
    margin-top: 6.09375rem; /* 8.125 * 0.75 */
  }

  .skillswrapper {
    font-size: 0.65625rem; /* 0.875 * 0.75 */
    line-height: 0.9375rem; /* 1.25 * 0.75 */
  }
  .skillswrapper span {
    font-size: 0.65625rem; /* 0.875 * 0.75 */
    line-height: 0.9375rem; /* 1.25 * 0.75 */
  }
  .lastSection {
    margin-top: 12.1875rem; /* 16.25 * 0.75 */
  }

  .wrapperofinterests {
    margin-top: 3rem;
    gap: 1rem 0.375rem;
  }

  .interesttext {
    font-size: 0.875rem;

    line-height: 1.25rem;
  }

  .learnmoreabout {
    font-size: 1rem;

    line-height: 1.5rem; /* 155.556% */
  }
  .learnmoreabout span {
    font-size: 1rem;

    line-height: 1.5rem;
  }
  .startonboard {
    margin-top: 2.375rem;

    font-size: 1rem;

    line-height: 1.5rem; /* 150% */
  }
  .welcomebackbutton {
    font-size: 0.875rem;
    height: 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .newHomeSlider-container {
    padding: 60px 0px 0px 0px;
  }
  .newHomeSecond {
    padding: 4.125rem 0rem;
  }
  .newHomeThird {
    padding: 2.5rem 0rem;
    background: #fff;
  }
  .interestcont {
    display: flex;
    position: relative;
    z-index: 4;
    padding: 10px;
    align-items: center;
    gap: 8px;
    border-radius: 30px;
    border-top: 2px solid var(--Base-White, #fff);
    border-left: 2px solid var(--Base-White, #fff);
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.7) 0%,
        rgba(255, 255, 255, 0.7) 100%
      ),
      rgba(255, 255, 255, 0.2);
  }
  .active {
    border-radius: 30px;
    border-color: #fecdd6;
    background: var(--Primary-400, #fd6f8e);

    /* Trans Card: Icons */
    box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
      0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
      0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
      0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
  }
  .forthsectionpadding {
    margin-top: 4.125rem;
    padding-top: 5.375rem;
  }
  .fourthsectionhome {
    width: 100%;
    min-height: 25.563rem;
    max-height: 25.563rem;
  }
  .belowlatest {
    width: auto;
  }

  .headerhomepage {
    padding: 0.375rem 5rem;
  }
  .headerlogohome {
    height: 2.75rem;
    width: 2.75rem;
  }
  .paddingslefttop {
    margin-top: 12.25rem;
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .paddingleftright {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .secondsecPad {
    margin-top: 12.25rem;
    margin-left: 5rem;
  }
  .fasttrackyour {
    color: var(--Gray-950, #0c111d);
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 155.556% */
  }

  .secondtextuni {
    margin-top: 2rem;
  }
  .carreerstartby {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .widthsettext {
    max-width: 29rem;
  }
  .unilistinputdiv {
    display: flex;
    width: 23.938rem;
    padding: 4px 14px;
    align-items: center;

    gap: 0.5rem;
  }
  .showUnilistmodal {
    width: 23.938rem;
    max-height: 37.438rem;
  }
  .belowuniselect {
    margin-top: 1.75rem;
  }
  .nexttopics {
    max-width: 38.313rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .nexttopics span {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .card1secondsec {
    max-width: 26.25rem;

    padding: 1.5rem 1.5rem 0rem 1.5rem;
  }

  .firstcardvirttext {
    font-size: 1.125rem;
    line-height: 1.75rem; /* 114.286% */
  }

  .card1challenge {
    display: flex;

    padding: 0.375rem 0.75rem;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.25rem; /* 142.857% */
  }

  .textundervitual {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .textundervitual span {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .HomeThirdSection {
    margin-top: 6.25rem;
  }
  .caseStudy {
    font-size: 16px;

    line-height: 24px; /* 150% */
  }
  .widthfirst {
    width: 50%;
  }
  .widthsecond {
    width: 50%;
  }

  .lastSection {
    margin-top: 11.25rem;
  }
  .startjourney {
    max-width: 23.188rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .horiline {
    margin-top: 3.75rem;
  }

  .marginfromlinetop {
    margin-top: 3.125rem;
  }
  .footer1st {
    width: 12.063rem;
  }
  .varyance {
    font-size: 1.125rem;

    line-height: 1.75rem; /* 133.333% */
  }
  .Accelerate {
    font-size: 1rem;

    line-height: 1.5rem; /* 155.556% */
  }
  .footerseconddiv {
    width: 9.125rem;
  }
  .megazineimg {
    width: 20px;
    height: 20px;
  }
  .secondsametexts {
    font-size: 1rem;

    line-height: 1.5rem;
  }
  .ltd {
    font-size: 0.875rem;

    line-height: 1.25rem;
  }

  .seconddivtop {
    padding: 6px 14px;
  }
  .latestupdate {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .belowlatest {
    font-size: 1rem;

    line-height: 1.5rem; /* 155.556% */
  }

  .gotaquestion {
    font-size: 2rem;

    line-height: 4.5rem; /* 120% */
  }
  .cardsmargintop {
    margin-top: 1.25rem;
  }

  .getinspired {
    font-size: 14px;
    line-height: 24px; /* 150% */
  }
  .getinspired span {
    font-size: 14px;
    line-height: 24px;
  }
}

@media screen and (max-width: 768px) {
  .forthsectionpadding {
    margin-top: 4.125rem;
    padding-top: 5.375rem;
  }
  .fourthsectionhome {
    background: none;
  }
  .learnmorewidth {
    width: 100%;
  }

  .headerhomepage {
    padding: 0.375rem 2.5rem;
  }
  .welcomemenucontainer {
    gap: 1rem;
  }
  .welcomebackbutton {
    font-size: 0.875rem;
    line-height: 1.25rem;

    padding: 0.75rem 1rem;
    gap: 0.5rem;
  }
  .paddingslefttop {
    margin-top: 11.25rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .paddingleftright {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .secondsecPad {
    margin-top: 11.25rem;
    margin-left: 2.5rem;
  }
  .fasttrackyour {
    color: var(--Gray-950, #0c111d);
    font-family: Poppins;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.75rem; /* 155.556% */
  }
  .secondtextuni {
    margin-top: 2rem;
  }
  .carreerstartby {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .widthsettext {
    max-width: 29rem;
  }
  .belowuniselect {
    margin-top: 2rem;
  }

  .nexttopics {
    max-width: 27.313rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .nexttopics span {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .firstcardvirttext {
    font-size: 1.125rem;
    line-height: 1.75rem; /* 114.286% */
  }
  .widthfirst {
    width: 100%;
  }
  .widthsecond {
    width: 100%;
  }

  .getpoints {
    font-size: 0.725rem;
  }
  .lastSection {
    margin-top: 8.75rem;
  }
  .startjourney {
    max-width: 100%;
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .horiline {
    margin-top: 2.5rem;
  }

  .marginfromlinetop {
    margin-top: 2.5rem;
  }
  .footerseconddiv {
    width: 11.813rem;
  }

  .wrapperofinterests {
    margin-top: 3rem;
    gap: 1rem 0.375rem;
    width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .headerhomepage {
    padding: 0.375rem 1.25rem;
  }

  .welcomebackbutton {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .paddingslefttop {
    margin-top: 8.75rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .paddingleftright {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .secondsecPad {
    margin-top: 8.75rem;
    margin-left: 1.25rem;
  }
  .fasttrackyour {
    color: var(--Gray-950, #0c111d);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
  }

  .secondtextuni {
    margin-top: 1.25rem;
  }

  .carreerstartby {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .uniselecttext {
    width: 100%;
    font-size: 0.875rem;
  }

  .showUnilistmodal {
    max-width: 20.938rem;
    max-height: 37.438rem;
  }
  .belowuniselect {
    margin-top: 1rem;
  }

  .personimage {
    width: 2.5rem;
    height: 2.5rem;
  }
  .nexttopics {
    max-width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .nexttopics span {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .SecondSection {
    margin-top: 4.688rem;
  }
  .caseStudy {
    font-size: 14px;

    line-height: 20px; /* 150% */
  }

  .lastSection {
    margin-top: 5rem;
  }
}

@media screen and (max-width: 450px) {
  .card1secondsec {
    max-width: 100%;
    padding: 1rem 1rem 0rem 1rem;
    min-height: 30.875rem !important;
  }
  .forthsectionmarginTop {
    margin-top: 1.25rem;
  }
  .startonboard {
    margin-top: 1.5rem;

    /* 150% */
  }

  .secondsecseconddiv {
    max-width: 90vw !important;
    min-width: 89vw !important;
  }
  .secondsecthirddiv {
    min-width: 90vw;
    max-width: 91vw;
    min-height: 30.875rem !important;
  }
  .unilistinputdiv {
    display: flex;
    width: 100%;
    padding: 4px 14px;
    align-items: center;
    gap: 0.5rem;
  }
  .showUnilistmodal {
    width: 100%;
    max-height: 37.438rem;
  }
  .HomeThirdSection {
    margin-top: 5rem;
  }
  .footerseconddiv {
    width: 100%;
  }
  .belowlatest {
    max-width: 100%;

    /* 155.556% */
  }

  /* asdfasdsa */
  .headerhomepage {
    padding: 0.375rem 1.25rem;
  }

  .welcomebackbutton {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .paddingslefttop {
    margin-top: 8.75rem;
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .paddingleftright {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .secondsecPad {
    margin-top: 8.75rem;
    margin-left: 1.25rem;
  }
  .fasttrackyour {
    color: var(--Gray-950, #0c111d);
    font-family: Poppins;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
  }

  .secondtextuni {
    margin-top: 1.25rem;
  }

  .carreerstartby {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .uniselecttext {
    font-size: 0.875rem;
  }

  /* .showUnilistmodal{
        max-width: 20.938rem;
        max-height: 37.438rem;
    } */
  .belowuniselect {
    margin-top: 1rem;
  }

  .personimage {
    width: 2.5rem;
    height: 2.5rem;
  }
  .nexttopics {
    max-width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  .nexttopics span {
    font-size: 1rem;
    line-height: 1.25rem;
  }
  .SecondSection {
    margin-top: 4.688rem;
  }
  .caseStudy {
    font-size: 14px;

    line-height: 20px; /* 150% */
  }

  .lastSection {
    margin-top: 5rem;
  }
  .secondsecseconddiv {
    width: 100%;
    min-height: 618px;
  }
  .wrapperofinterests {
    margin-top: 1.5rem;
    gap: 1rem 0.375rem;
    width: 100%;
  }
}

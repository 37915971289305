.secondbuttontext {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 6px;
  background: transparent;
  border: none;
}
.secondbuttontext:hover {
  color: #e31b54;
}
.overflow {
  display: inline-flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: #fff;
  color: var(--Gray-600, #475467);
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
}
.createchallenge {
  display: flex;
  padding: 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: var(--Primary-600, #e31b54);

  color: var(--Snow, #fff);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.statsdiv {
  display: flex;

  padding: 1.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 5px;
  background: var(--Base-White, #fff);

  /* Trans Card: Icons */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
}

.statstextfirst {
  margin: 0;
}

.statstextfirst {
  color: var(--Gray-900, #101828);
  /* Text md/Semibold */
  font-family: "Poppins";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.behindbuttons {
  display: flex;

  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.4);

  /* Trans Card: Icons */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
}
.behindbuttons * {
  transition: all 0.3s ease-in-out;
}
.selectedbutton,
.selectedbutton:focus {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex: 1 0 0;
  border-radius: 6px;
  background: var(--Primary-50, #fff);
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  outline: none; /* Remove default focus outline */
}

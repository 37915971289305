.outerdivofresult {
  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 5px;
  background: #fff;
  min-width: 364px;
  max-width: 364px;
  /* Trans Card: Icons */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
}

.countryholder {
  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: #fcfcfd;
  color: var(--gray-500-p, #667085);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.usernameinresults {
  color: var(--Primary-600, #e31b54);

  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%; /* 29.7px */
}
.unidetailsresult {
  color: var(--Gray-700, #344054);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
}

.unidetailsresult span {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
}

.skillsandinterest {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
}
.skillsresultwrapper {
  display: flex;
  height: 36px;
  padding: 12px 14px;
  align-items: center;
  gap: 8px;
  border-radius: 180px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);
}
.skillsresultwrapper p {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}

.flexwrapperskillsresults {
  display: flex;
  margin-top: 12px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px 8px;
  flex-wrap: wrap;
}

/* .slick-track {
    margin-top: 0px;
    min-height: 500px;
    overflow: visible; 
   
  } */

/* .slick-slider *{
    overflow: visible;
} */

.Messagebutton {
  display: flex;
  width: 133px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Base-White, #fff);

  /* Secoundary Btn Shadow */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.ShortlistButton {
  display: flex;

  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: var(--Primary-600, #e31b54);
  color: var(--Snow, #fff);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.ShortlistButton:hover {
  display: inline-flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 6px;
  background: var(--Primary-700, #c01048);

  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);

  color: var(--Snow, #fff);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}

.Messagebutton:hover {
  border-radius: 6px;
  border: 1px solid var(--Gray-300, #d0d5dd);
  background: var(--Secondary-25, #f5f8ff);

  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}

.resultuserName {
  color: var(--grayscale-black, #252733);
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 145%; /* 20.3px */
}
.resultintaketable {
  color: var(--Gray-500, #667085);
  font-family: "Poppins";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%; /* 17.4px */
}
.resultuniname {
  color: var(--grayscale-black, #252733);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 20.3px */
}

.banner_wrapperad {
  /* White color with 0.6 opacity */
  background-image: url(https://varyance.b-cdn.net/uploads/organbackground.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-width: 96vw;
  border: solid 1px #fff;
  border-radius: 20px;
  border-radius: 30px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;

  /* Trans Card v2 */
}

/* Optional: You can add media queries to adjust styles for different screen sizes */
@media (max-width: 768px) {
  .banner_wrapperad {
    margin-left: 10px; /* Adjust the margin for smaller screens */
    /* Take full width on smaller screens */
    min-width: 100vw;
    min-height: 100vh;
    background-size: cover;
    margin-top: 0px;
    margin-left: 0px;
  }
  .backgroundimg {
    padding: 0px;
    display: block;
  }
}
.backgroundimg {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  background: url(https://varyance.b-cdn.net/uploads/newCompanyBackground.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.main-container {
  background-color: white;
  scroll-snap-align: end;
  // padding-top: 2%;
}

.hor-scroll {
  position: relative;
  height: 180vh;

  background-repeat: no-repeat;
  background-size: cover;
  overflow-y: clip;
}

.hor-scroll-con {
  position: sticky;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-around;
  overflow: hidden;
  padding: 4%;
}
.scroll-heading {
  width: auto;
  height: auto;
  padding-left: 1%;
  font-size: x-large;
  z-index: 99;
}

.motion-div {
  display: flex;
  justify-content: space-evenly;
  align-items: start;
  gap: 3rem;
  // margin-left: 13%;
}

.image-div {
  width: 30vw;
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 2rem;
}
.image-div img {
  width: 100%;
  height: 100%;
  // box-shadow: 30px;
  box-shadow: 0 0 10px rgb(213, 208, 237);
}

.image-div1 {
  width: 100%;
  height: 40%;
  object-fit: inherit;
  border-radius: 10px;
}
.image-div2 {
  width: 100%;
  height: 40%;
  object-fit: inherit;
  border-radius: 10px;
}

.scroll-img {
  width: 30vw;
  height: 688.542px;

  object-fit: fill;
  border-radius: 10px;
  background: #fff;

  /* Trans Card v2 */
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}

.extra-comp {
  width: 100vw;
  height: 100vh;
  scroll-snap-align: start;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section2headings {
  color: var(--Gray-900, #101828);

  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.section2text {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%; /* 26.1px */
}
.secondbackground {
  background-image: url(../../../public/rs2.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 30vw;
  height: 688.542px;
  border-radius: 10px;

  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.thirdbackground {
  background-color: #f0f5fe;
  background-repeat: no-repeat;
  background-size: cover;
  height: 688.542px;

  border-radius: 10px;

  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
}
.secondbackground {
  display: block;
}
.secondSectionHeading {
  color: var(--Base-White, #fff);

  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 28px */
}
.themetext {
  color: var(--Primary-600, #e31b54);
  /* text button */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 145%; /* 23.2px */
}

#Step1Ki3 {
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translateX(-50%, -50%);
  // width: 60%;
  .stepWrapper {
    text-align: left;
  }
  .backButton {
    cursor: pointer;
  }
  .section_title {
    h3 {
      color: #101828; //mostlyused
      font-size: 32px;
    }
  }
  .interest_wrapper {
    // margin-top: clamp(2rem,4vw,4.813rem);
    margin-top: 1.5rem;
    .interest_container {
      display: flex;
      justify-content: start;
      align-content: center;
      flex-wrap: wrap;
      gap: 1rem 0.375rem;
    }
    .interest_Item {
      display: flex;
      padding: 0.625rem;
      align-items: center;
      gap: 0.5rem;
      border-radius: 1.875rem;
      border-top: 2px solid var(--Base-White, #fff);
      border-left: 2px solid var(--Base-White, #fff);
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.7) 0%,
          rgba(255, 255, 255, 0.7) 100%
        ),
        rgba(255, 255, 255, 0.2);
      cursor: pointer;
      &.active {
        color: #fff;
        display: flex;
        padding: 10px;
        align-items: center;
        gap: 8px;
        border-radius: 30px;
        border-color: #fecdd6;
        background: var(--Primary-400, #fd6f8e);

        /* Trans Card: Icons */
        box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
          0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
          0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
          0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
        &:hover {
          background: var(--primary-300, #fea3b4);
          box-shadow: none;
        }
      }
      &:hover {
        background: var(--primary-50, #fff1f3);
        border-color: #fff1f3;
        box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
          0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
          0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
          0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
          0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
          0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
      }
      .Interest_text {
        color: var(--Gray-800, #1d2939);
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
      }
      p {
        text-align: center;
        margin: auto auto;
      }
      .interest_icon {
        height: 24px;
        width: 24px;
      }
    }
  }
}
.supportbuttontext {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.sectionheadingclamped {
  color: #0c111d;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  font-size: clamp(1.25rem, 4vw, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  text-align: start;
}
@media only screen and (max-width: 768px) {
  #Step1Ki3 .interest_wrapper .interest_Item .Interest_text {
    color: var(--Gray-800, #1d2939);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  #Step1Ki3 .interest_wrapper .interest_Item {
    padding: 5px;
    gap: 8px;
  }
  .active {
    padding: 5px !important;
    gap: 8px !important;
  }
}

#Step6KSJen {
  width: 100%;
  .stepWrapper {
    text-align: left;
  }

  .section_title {
    h3 {
      color: #101828; //mostlyused
    }
  }
  .Form_first_wrapper {
    .Input_wrapper {
      min-height: 150px;
      width: 100%;
      height: 100%;
      position: relative;
    }
    .Name_field {
      background-color: transparent;
      font-size: 51px;
      color: var(--primaryColor);
      outline: none;
      border: none;
      width: 100%;
      @media only screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &::placeholder {
        color: var(--primaryColor);
        opacity: 0.2; /* Firefox */
      }
    }
  }

  .card {
    width: 623px;
    padding: 10px 40px 40px;
    .CardLogo {
      float: right;
    }
    .nameLetter {
      height: 120px;
      width: 120px;
      background-color: #fff5f6;
      border: 5px solid #fff;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      text-transform: uppercase;
      h2 {
        color: #101828; //mostlyused
        font-size: 30px;
        line-height: 27px;
      }
    }
    .card_details {
      margin-top: 40px;
      h3 {
        color: #101828; //mostlyused
        text-transform: capitalize;
        font-size: 32px;
        line-height: 43px;
      }
    }

    .card_moreInfo {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 12px;
      flex-direction: row;

      .countryLogo {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 12px;
        flex-direction: row;

        .imgCountryContainer {
          height: 25px;
          width: 25px;
          img {
            border-radius: 100%;
            height: 100%;
            width: 100%;
            height: 26px;
            width: 26px;
            object-fit: cover;
          }
        }
        .countryName {
          color: var(--Gray-600, #475467);
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
        }
      }
      .interest_item {
        background-color: #fff5f6;
        border-radius: 30px;
        width: max-content;
        padding: 7px 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;

        .Interest_img_icon {
          height: 16px;
          width: 16px;
        }
        .Interest_text {
          margin: 0;
          font-weight: 300;
          font-size: 12px;
          color: #667085;
          line-height: 14px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .card {
      width: 100%;
    }
    .Name_field {
      position: relative;
      top: 10px;
    }
  }
}

.namecountry {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}
.interest_itemaaa {
  display: inline-flex;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: #fff;
}
.studentat {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 28.8px */
}

.HomeBannerSection {
  min-height: 100vh;
  min-width: 100%;
  width: 100vw;
  flex-shrink: 0;
  padding: 40px;

  .banner_wrapper {
    // background-color: #44b09e;
    // background-image: linear-gradient(315deg, #44b09e 0%, #e0d2c7 74%);
    background-image: url(https://varyance.b-cdn.net/uploads/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100%;
    min-height: 95vh;
    // margin: auto auto;
    border-radius: 20px;
    padding-left: 73px;
  }

  .gropu1Sectin {
    padding-top: 23px;
    height: 100%;
    display: flex;
    justify-content: start;
    align-content: center;
    .group_wrapper {
      .title {
        h2 {
          font-size: 48px;
          color: #101828;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          max-width: 722px;
        }
        p {
          max-width: 656px;
          height: auto;
          // font-family: "Poppins", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 145%;
          color: #0c111d;
          margin-top: 2px;
        }
      }
      .searchInput {
        margin-top: 72px;
        p {
          max-width: 167px;
          font-size: 16px;
          margin-bottom: 20px;
          font-family: sans-serif;
          font-weight: 600;
        }
        .wrapper {
          background-color: #fff;
          border-radius: 180px;
          width: 100%;
          max-width: 512px;

          padding: 0 20px;
          gap: 10px;
          display: flex;
          justify-content: start;
          align-items: center;
          flex-direction: row;
          img {
            height: 40px;
            max-width: 40px;
            border-radius: 100%;
          }
          #UniDrop {
            outline: none;
            border: none;
            width: 100%;
            font-size: 14px;
          }
        }
        .searchContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 30px;
          flex-wrap: wrap;
        }

        // .imgGroup {

        //   img {
        //     margin-left: -15px;
        //     height: 60px;
        //     width: 60px;
        //     // border: 3px solid #fff;
        //     // border-radius: 100%;
        //   }
        // }
      }
    }
    .quick_links {
      margin-top: 204px;
      h4 {
        font-family: sans-serif;
        font-size: 16px;
        font-weight: 500;
      }
      .LocationsContainer {
        margin-top: 1rem;
        display: flex;
        justify-content: start;
        align-content: center;
        gap: 7px;
        flex-direction: row;
        flex-wrap: wrap;
        width: 70%;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
        .locations {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 6px;
          flex-direction: row;
          max-width: max-content;
          padding: 6px 8px;
          border: 1px solid #eff0f1;
          border-radius: 30px;
          background: var(--gray-25, #fcfcfd);
          color: #667085;
          margin-bottom: 17px;
          margin-right: 10px;

          img {
            max-width: 16px;
            object-fit: cover;
            border-radius: 100%;
          }
          p {
            font-family: sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: #667085;
          }
        }
      }
    }
  }

  .logo-grid {
    // padding-left: 12.5vw;
    // border: 1px solid black;
    max-width: 372px;

    display: flex;
    margin-top: 29px;
    display: flex;
    align-items: center;
    // margin-left: 108px;

    // margin-left: 115px;
  }
  .logo-g {
    margin-right: 12px;
    width: 64px;
    height: 64px;
    background: #fff;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    /* Transp Card v2. */
    box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
      0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
      0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
      0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
      0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
      0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
  }
  .arrow-logo {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 41px;
    padding: 8px;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 50px;
    background: #fff;
    object-fit: contain;
    /* Transp Card v2. */
    box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
      0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
      0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
      0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
      0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
      0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
  }

  .Group_wrapper {
    border: 1px solid black;
    // display: flex;
    flex-direction: column;
    margin-left: 1108px;
    position: relative;
    .groupImg {
      // margin-left: 1200px;
      // margin-top: 92px;
      display: flex;
      justify-content: end;
      align-content: center;
      img {
        position: relative;
        right: 0;
        float: right;
        margin-right: 0;
      }
    }
    .titles {
      margin-top: 1rem;
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 12px;
      flex-direction: column;
    }
    .titleItem2 {
      opacity: 0.5;
    }
    .titleItem3 {
      position: relative;
      opacity: 0.2;
      h3 {
        background: -webkit-linear-gradient(#333, transparent);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text; /* Add the standard property */
      }
      .glassEffect {
        position: absolute;
        top: 12px;
        height: 60%;
        width: 100%;
        background: rgba(255, 255, 255, 0.07);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(14.9px);
        -webkit-backdrop-filter: blur(14.9px);
        border: 1px solid rgba(255, 255, 255, 0.08);
      }
    }
    .title_item {
      text-align: left;
      width: 100%;
      h3 {
        font-size: 20px;
      }
      p {
        font-family: sans-serif;
      }
      @media only screen and (max-width: 768px) {
        padding-left: 15px;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    padding: 10px;
    padding-top: 15px;
  }
}

.grid-wrapper {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-left: 9vw;
  padding-top: 50px;
}

.grid-cards {
  display: flex;
}

/* Example Media Query for smaller screens */
@media only screen and (max-width: 768px) {
  .HomeBannerSection {
    padding: 10px;
    padding-top: 15px;

    .banner_wrapper {
      padding-left: 10px; /* Adjust as needed */
      width: 100%; /* Adjust as needed */
    }

    .gropu1Sectin {
      padding-top: 100px; /* Adjust as needed */
      // Other responsive adjustments as needed
    }

    .quick_links {
      margin-top: 50px; /* Adjust as needed */
    }

    .logo-grid {
      width: 100%; /* Adjust as needed */
      // Other responsive adjustments as needed
    }
  }

  /* Additional Media Queries as needed */
}

@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.main-card-container {
  width: 326px;
  height: 373px;
  flex-shrink: 0;
  margin-right: 12.3px;
  border-radius: 9.328px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 11px;
  //   align-items: center;
  font-size: 16px;
  box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
    0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
    0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
    0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
    0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
    0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
  // font-family: sans-serif;
  font-family: "Poppins", sans-serif;

  .grey-card-container {
    display: flex;
    flex-direction: column;
    width: 304px;
    height: 300px;
    //     justify-content: space-between;
    //     align-items: center;
    //     justify-content: space-between;
    border-radius: 10px;
    background: var(--secondary-25, #f5f8ff);
    margin-bottom: 11px;

    .card-bg {
      height: 166.7px;
      width: 304px;
      border-radius: 12px 12px 0px 0px;
      border: none;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      margin-bottom: 23.22px;
      position: relative;
      z-index: 1; /* Add this line */
    }

    .bg {
      position: absolute;
      z-index: 99;
      width: 64px;
      height: 64px;
      flex-shrink: 0;
      filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.1));

      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;
      margin-top: 16px;
      margin-left: 16px;
      border-radius: 9px;
    }

    // .bg img{
    //   width: 40px;
    //   height: 40px;
    // }

    .card-task-p {
      font-size: 10px;
      color: #98a2b3;
      font-family: sans-serif;
      font-style: normal;
      font-weight: 500;
      margin-left: 16px;
      margin-bottom: 8px;
      font-family: "Poppins", sans-serif;
    }

    .card-sub-heading {
      color: #e13551;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 145%;
      margin-left: 16px;
      margin-bottom: 6px;
      font-family: "Poppins", sans-serif;
    }

    .title-heading {
      color: #171721;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      margin-left: 16px;
      font-family: "Poppins", sans-serif;
    }
  }

  .card-footer {
    display: flex;
    width: 304px;
    justify-content: space-between;
    align-items: center;
  }

  .ft-active {
    margin-left: 11px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    height: 22px;
    width: auto;
  }

  .green {
    width: 10.14px;
    height: 10.124px;
    background-color: #5fdcb3;
    border-radius: 50%;
    margin-right: 10px;
  }

  .ft-active {
    color: #344054;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    font-family: "Poppins", sans-serif;

    //     font-family: sans-serif;
  }
  .detail-button {
    display: flex;
    width: 112px;
    border: transparent;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 6px;
    background-color: var(--primary-200, #fecdd6);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #fff;
    font-family: "Poppins", sans-serif;

    /* Trans Card: Icons */
    box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
      0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
      0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
      0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
  }

  .detail-button:hover {
    background-color: #6b8cff;
  }
}
.ft-active {
  margin-left: 11px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  height: 22px;
  width: auto;
}

.green {
  width: 10.14px;
  height: 10.124px;
  background-color: #5fdcb3;
  border-radius: 50%;
  margin-right: 10px;
}

.ft-active {
  color: #344054;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%;
  font-family: "Poppins", sans-serif;

  //     font-family: sans-serif;
}
#Step1Ki3 {
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translateX(-50%, -50%);
  // width: 60%;
  .stepWrapper {
    text-align: left;
  }
  .backButton {
    cursor: pointer;
  }
  .section_title {
    h3 {
      color: #101828; //mostlyused
      font-size: 32px;
    }
  }
  .interest_wrapper {
    // margin-top: clamp(2rem,4vw,4.813rem);
    margin-top: 1.5rem;
    .interest_container {
      display: flex;
      justify-content: start;
      align-content: center;
      flex-wrap: wrap;
      gap: 1rem 0.375rem;
    }
    .interest_Item {
      display: flex;
      padding: 0.625rem;
      align-items: center;
      gap: 0.5rem;
      border-radius: 1.875rem;
      border-top: 2px solid var(--Base-White, #fff);
      border-left: 2px solid var(--Base-White, #fff);
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.7) 0%,
          rgba(255, 255, 255, 0.7) 100%
        ),
        rgba(255, 255, 255, 0.2);
      cursor: pointer;
      &.active {
        color: #fff;
        display: flex;
        padding: 10px;
        align-items: center;
        gap: 8px;
        border-radius: 30px;
        border-color: #fecdd6;
        background: var(--Primary-400, #fd6f8e);

        /* Trans Card: Icons */
        box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
          0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
          0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
          0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04);
        &:hover {
          background: var(--primary-300, #fea3b4);
          box-shadow: none;
        }
      }
      &:hover {
        background: var(--primary-50, #fff1f3);
        border-color: #fff1f3;
        box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
          0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
          0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
          0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
          0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
          0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
      }
      .Interest_text {
        color: var(--Gray-800, #1d2939);
        font-family: Poppins;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
      }
      p {
        text-align: center;
        margin: auto auto;
      }
      .interest_icon {
        height: 24px;
        width: 24px;
      }
    }
  }
}
.supportbuttontext {
  color: var(--Gray-950, #0c111d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
}

.sectionheadingclamped {
  color: #0c111d;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  font-size: clamp(1.25rem, 4vw, 2rem);
  font-style: normal;
  font-weight: 400;
  line-height: 37px;
  text-align: start;
}
@media only screen and (max-width: 768px) {
  #Step1Ki3 .interest_wrapper .interest_Item .Interest_text {
    color: var(--Gray-800, #1d2939);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  #Step1Ki3 .interest_wrapper .interest_Item {
    padding: 5px;
    gap: 8px;
  }
  .active {
    padding: 5px !important;
    gap: 8px !important;
  }
}

#Step6KSJen {
  width: 100%;
  .stepWrapper {
    text-align: left;
  }

  .section_title {
    h3 {
      color: #101828; //mostlyused
    }
  }
  .Form_first_wrapper {
    .Input_wrapper {
      min-height: 150px;
      width: 100%;
      height: 100%;
      position: relative;
    }
    .Name_field {
      background-color: transparent;
      font-size: 51px;
      color: var(--primaryColor);
      outline: none;
      border: none;
      width: 100%;
      @media only screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &::placeholder {
        color: var(--primaryColor);
        opacity: 0.2; /* Firefox */
      }
    }
  }

  .card {
    width: 623px;
    padding: 10px 40px 40px;
    .CardLogo {
      float: right;
    }
    .nameLetter {
      height: 120px;
      width: 120px;
      background-color: #fff5f6;
      border: 5px solid #fff;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      text-transform: uppercase;
      h2 {
        color: #101828; //mostlyused
        font-size: 30px;
        line-height: 27px;
      }
    }
    .card_details {
      margin-top: 40px;
      h3 {
        color: #101828; //mostlyused
        text-transform: capitalize;
        font-size: 32px;
        line-height: 43px;
      }
    }

    .card_moreInfo {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 12px;
      flex-direction: row;

      .countryLogo {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 12px;
        flex-direction: row;

        .imgCountryContainer {
          height: 25px;
          width: 25px;
          img {
            border-radius: 100%;
            height: 100%;
            width: 100%;
            height: 26px;
            width: 26px;
            object-fit: cover;
          }
        }
        .countryName {
          color: var(--Gray-600, #475467);
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
        }
      }
      .interest_item {
        background-color: #fff5f6;
        border-radius: 30px;
        width: max-content;
        padding: 7px 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;

        .Interest_img_icon {
          height: 16px;
          width: 16px;
        }
        .Interest_text {
          margin: 0;
          font-weight: 300;
          font-size: 12px;
          color: #667085;
          line-height: 14px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .card {
      width: 100%;
    }
    .Name_field {
      position: relative;
      top: 10px;
    }
  }
}

.namecountry {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}
.interest_itemaaa {
  display: inline-flex;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: #fff;
}
.studentat {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 28.8px */
}

.HomeBannerSection {
  min-height: 100vh;
  min-width: 100%;
  width: 100vw;
  flex-shrink: 0;
  padding: 40px;

  .banner_wrapper {
    // background-color: #44b09e;
    // background-image: linear-gradient(315deg, #44b09e 0%, #e0d2c7 74%);
    background-image: url(https://varyance.b-cdn.net/uploads/bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    min-width: 100%;
    min-height: 95vh;
    // margin: auto auto;
    border-radius: 20px;
    padding-left: 73px;
  }

  .gropu1Sectin {
    padding-top: 23px;
    height: 100%;
    display: flex;
    justify-content: start;
    align-content: center;
    .group_wrapper {
      .title {
        h2 {
          font-size: 48px;
          color: #101828;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          max-width: 722px;
        }
        p {
          max-width: 656px;
          height: auto;
          // font-family: "Poppins", sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: 145%;
          color: #0c111d;
          margin-top: 2px;
        }
      }
      .searchInput {
        margin-top: 72px;
        p {
          max-width: 167px;
          font-size: 16px;
          margin-bottom: 20px;
          font-family: sans-serif;
          font-weight: 600;
        }
        .wrapper {
          background-color: #fff;
          border-radius: 180px;
          width: 100%;
          max-width: 512px;

          padding: 0 20px;
          gap: 10px;
          display: flex;
          justify-content: start;
          align-items: center;
          flex-direction: row;
          img {
            height: 40px;
            max-width: 40px;
            border-radius: 100%;
          }
          #UniDrop {
            outline: none;
            border: none;
            width: 100%;
            font-size: 14px;
          }
        }
        .searchContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 30px;
          flex-wrap: wrap;
        }

        // .imgGroup {

        //   img {
        //     margin-left: -15px;
        //     height: 60px;
        //     width: 60px;
        //     // border: 3px solid #fff;
        //     // border-radius: 100%;
        //   }
        // }
      }
    }
    .quick_links {
      margin-top: 204px;
      h4 {
        font-family: sans-serif;
        font-size: 16px;
        font-weight: 500;
      }
      .LocationsContainer {
        margin-top: 1rem;
        display: flex;
        justify-content: start;
        align-content: center;
        gap: 7px;
        flex-direction: row;
        flex-wrap: wrap;
        width: 70%;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
        .locations {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 6px;
          flex-direction: row;
          max-width: max-content;
          padding: 6px 8px;
          border: 1px solid #eff0f1;
          border-radius: 30px;
          background: var(--gray-25, #fcfcfd);
          color: #667085;
          margin-bottom: 17px;
          margin-right: 10px;

          img {
            max-width: 16px;
            object-fit: cover;
            border-radius: 100%;
          }
          p {
            font-family: sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: #667085;
          }
        }
      }
    }
  }

  .logo-grid {
    // padding-left: 12.5vw;
    // border: 1px solid black;
    max-width: 372px;

    display: flex;
    margin-top: 29px;
    display: flex;
    align-items: center;
    // margin-left: 108px;

    // margin-left: 115px;
  }
  .logo-g {
    margin-right: 12px;
    width: 64px;
    height: 64px;
    background: #fff;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    /* Transp Card v2. */
    box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
      0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
      0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
      0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
      0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
      0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
  }
  .arrow-logo {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 41px;
    padding: 8px;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 50px;
    background: #fff;
    object-fit: contain;
    /* Transp Card v2. */
    box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
      0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
      0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
      0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
      0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
      0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
  }

  .Group_wrapper {
    border: 1px solid black;
    // display: flex;
    flex-direction: column;
    margin-left: 1108px;
    position: relative;
    .groupImg {
      // margin-left: 1200px;
      // margin-top: 92px;
      display: flex;
      justify-content: end;
      align-content: center;
      img {
        position: relative;
        right: 0;
        float: right;
        margin-right: 0;
      }
    }
    .titles {
      margin-top: 1rem;
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 12px;
      flex-direction: column;
    }
    .titleItem2 {
      opacity: 0.5;
    }
    .titleItem3 {
      position: relative;
      opacity: 0.2;
      h3 {
        background: -webkit-linear-gradient(#333, transparent);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text; /* Add the standard property */
      }
      .glassEffect {
        position: absolute;
        top: 12px;
        height: 60%;
        width: 100%;
        background: rgba(255, 255, 255, 0.07);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(14.9px);
        -webkit-backdrop-filter: blur(14.9px);
        border: 1px solid rgba(255, 255, 255, 0.08);
      }
    }
    .title_item {
      text-align: left;
      width: 100%;
      h3 {
        font-size: 20px;
      }
      p {
        font-family: sans-serif;
      }
      @media only screen and (max-width: 768px) {
        padding-left: 15px;
      }
    }
  }

  @media only screen and (max-width: 992px) {
    padding: 10px;
    padding-top: 15px;
  }
}

.grid-wrapper {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  padding-left: 9vw;
  padding-top: 50px;
}

.grid-cards {
  display: flex;
}

/* Example Media Query for smaller screens */
@media only screen and (max-width: 768px) {
  .HomeBannerSection {
    padding: 10px;
    padding-top: 15px;

    .banner_wrapper {
      padding-left: 10px; /* Adjust as needed */
      width: 100%; /* Adjust as needed */
    }

    .gropu1Sectin {
      padding-top: 100px; /* Adjust as needed */
      // Other responsive adjustments as needed
    }

    .quick_links {
      margin-top: 50px; /* Adjust as needed */
    }

    .logo-grid {
      width: 100%; /* Adjust as needed */
      // Other responsive adjustments as needed
    }
  }

  /* Additional Media Queries as needed */
}

#Step2Ki33 {
  width: 100%;

  .boxShadow {
    box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
      0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
      0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
      0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
      0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
      0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
    padding: 0%;
  }

  .stepWrapper {
    text-align: left;
    width: 100%;
  }

  .section_title {
    h3 {
      color: #101828; //mostlyused
    }
  }
  .Form_first_wrapper {
    .Input_wrapper {
      min-height: 150px;
      width: 100%;
      height: 100%;
      position: relative;
    }
    .Name_field {
      background-color: transparent;
      font-size: clamp(2rem, 4vw, 3.5rem) !important;
      color: var(--primaryColor);
      outline: none;
      border: none;
      width: 100%;
      @media only screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &::placeholder {
        color: var(--primaryColor);
        opacity: 0.2; /* Firefox */
      }
    }
  }

  .cardNameofuser {
    color: #000;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;

    font-size: clamp(1.25rem, 4vw, 1.875rem);
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 133.333% */
  }

  .card {
    width: 100%;
    max-width: 540px;
    padding: 31px 26px 31px 43px;
    .CardLogo {
      float: right;
    }
    .nameLetter {
      min-height: 90px;
      min-width: 90px;
      background-color: #fff5f6;
      border: 5px solid #fff;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      text-transform: uppercase;
      h2 {
        color: #101828; //mostlyused
        font-size: 30px;
        line-height: 27px;

        // position: absolute;
        // left: 37%;
        // top: 39%;
      }
    }
    .card_details {
      h3 {
        color: #101828;
        text-transform: capitalize;
        font-size: 32px;
        line-height: 43px;
      }
      // p {
      //   color: #475467;
      //   font-weight: 600;
      //   font-size: 18px;
      //   line-height: 28px;
      //   color: rgba(71, 84, 103, 1);
      //   font-family: "Poppins", sans-serif;
      // }
    }

    .card_moreInfo {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 12px;
      flex-direction: row;

      .countryLogo {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 12px;
        flex-direction: row;

        .imgCountryContainer {
          height: 25px;
          width: 25px;
          img {
            border-radius: 100%;
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
      .interest_item {
        background-color: #fff5f6;
        border-radius: 30px;
        width: max-content;
        padding: 7px 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;

        .Interest_img_icon {
          height: 16px;
          width: 16px;
        }
        .Interest_text {
          margin: 0;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #667085;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .card {
      width: 100%;
    }
    .Name_field {
      position: relative;
      top: 10px;
    }
  }
}

#Step4Kxi39 {
  width: 100%;

  .stepWrapper {
    text-align: left;
  }

  .section_title {
    h3 {
      color: #101828; //mostlyused
    }
  }
  .card_wrapper {
    border: none;
    padding: 5px 10px;
  }
  .LogoSlides {
    margin: 20px auto;
  }
  .Next_arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -50px;
    border-radius: 100%;
    padding: 2px 4px;
    background-color: #fff;
    color: #101828; //mostlyused
    cursor: pointer;
    box-shadow: 3px 10px 13px -7px rgba(188, 188, 188, 0.75);
  }
  .Prev_arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -50px;
    cursor: pointer;
    border-radius: 100%;
    padding: 2px 4px;
    background-color: #fff;
    color: #101828; //mostlyused
    box-shadow: 3px 10px 13px -7px rgba(188, 188, 188, 0.75);
  }
  .carda {
    // width: 130px;
    height: 180px;
    width: 180px;
    padding: 20px 20px;
    &:hover {
      // background: var(--primary-50, #FFF1F3);
      box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
        0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
        0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
        0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
        0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
        0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
    }
    .Logo_img {
      height: 100px;
      // width: 125px;
      // width: 100%;
      margin: auto;
    }
  }
  @media only screen and (max-width: 600px) {
    .Next_arrow,
    .Prev_arrow {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
    .carda {
      min-height: 180px;
      .Logo_img {
        height: 80%;
        // width: 80%;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .carda {
      min-height: 220px;
      padding: 10px 10px;
      .Logo_img {
        height: 80%;
        // width: 60%;
      }
    }
  }
}
.stepWrapper .slick-track {
  margin-top: 40px;
  height: 300px;
}
.card_wrapper {
  border: 1px solid transparent; /* Default border */
  transition: border-color 0.3s ease; /* Transition for smooth effect */

  &.selected {
    background: #f0829a;

    .carda {
      background: var(--Primary-400, #fad1da);
      /* Trans Card: Icons */
      // img {
      //   filter: brightness(
      //     80%
      //   ); /* Adjust brightness for a light red color effect on the image */
      // }
    }
  }
}

#Step5Kd49 {
  width: 100%;

  .stepWrapper {
    text-align: left;
  }

  .section_title {
    h3 {
      color: #101828; //mostlyused
    }
  }

  .LogoSlides {
    margin: 20px auto;
  }
  .Next_arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -50px;
    border-radius: 100%;
    padding: 2px 4px;
    background-color: #fff;
    color: #101828; //mostlyused
    cursor: pointer;
    box-shadow: 3px 10px 13px -7px rgba(188, 188, 188, 0.75);
  }
  .Prev_arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -50px;
    cursor: pointer;
    border-radius: 100%;
    padding: 2px 4px;
    background-color: #fff;
    color: #101828; //mostlyused
    box-shadow: 3px 10px 13px -7px rgba(188, 188, 188, 0.75);
  }

  .card_wrapper {
    padding: 10px;
    .card {
      box-shadow: 0px 2.1907448768615723px 1.7525957822799683px 0px #6c49ac05;
      box-shadow: 0px 5.264664173126221px 4.211731433868408px 0px #6c49ac07;
      box-shadow: 0px 9.912895202636719px 7.93031644821167px 0px #6c49ac09;
      box-shadow: 0px 17.682912826538086px 14.146328926086426px 0px #6c49ac0b;
      box-shadow: 0px 33.073944091796875px 26.45915412902832px 0px #6c49ac0d;
      box-shadow: 0px 79.16667175292969px 63.333335876464844px 0px #6c49ac12;
      border-radius: 5px;
      // max-width: 320px;
      padding: 20px;
      background-color: rgba(255, 255, 255, 0.1);

      .Join_mark {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 100%;
        .TypeLogo {
          padding: 5px 7px;
          background-color: #eeeaf2;
          border-radius: 100%;
          img {
            height: 24px;
            width: 24px;
          }
          transition: all 0.3s ease-in-out;
        }
      }
    }
    .card_title {
      margin-top: 20px;
      color: #101828; //mostlyused
      h4 {
        font-size: 14px;
        font-weight: bold;
        font-family: "Manrope", sans-serif;
      }
    }
    .colorMark {
      color: #5fdcb3 !important;
    }
    .card_details {
      margin-top: 30px;
      p {
        font-size: 12px;
        font-weight: 400;
        font-family: "Manrope", sans-serif;
      }
    }
    .card_timing {
      margin-top: 12px;
      display: flex;
      justify-content: start;
      align-items: start;
      flex-direction: column;
      gap: 8px;
      .cart_item {
        width: 100%;
        display: flex;
        flex-direction: row;
      }
      .groupImg {
        display: flex;
        justify-content: start;
        align-items: center;
        img {
          height: 20px;
          width: 20px;
          margin-left: -6px;
        }
      }

      .Time_item {
        p {
          font-family: "Manrope", sans-serif;
          font-size: 10px;
        }
        display: flex;
        justify-content: start;
        align-content: center;
        gap: 5px;
        flex-direction: row;
      }
    }
    .gapCs-3 {
      gap: 20px;
    }
    &:hover {
      .Join_mark {
        .TypeLogo {
          transform: scale(1.02);
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .Next_arrow,
    .Prev_arrow {
      display: none;
    }
  }

  @media only screen and (max-width: 600px) {
  }
  @media only screen and (max-width: 480px) {
  }
}

/* Add the following styles at the end of your style.scss file */

.card {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.card:hover {
  transform: scale(
    1.05
  ); /* You can adjust the scale factor according to your preference */
}

.namecountry {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}
.interest_itemaaa {
  display: inline-flex;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: #fff;
}
.studentat {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 28.8px */
}

#Step6KSJen {
  width: 100%;
  .stepWrapper {
    text-align: left;
  }

  .section_title {
    h3 {
      color: #101828; //mostlyused
    }
  }
  .Form_first_wrapper {
    .Input_wrapper {
      min-height: 150px;
      width: 100%;
      height: 100%;
      position: relative;
    }

    .Name_field {
      background-color: transparent;
      font-size: 51px;
      color: var(--primaryColor);
      outline: none;
      border: none;
      width: 100%;
      @media only screen and (min-width: 768px) {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &::placeholder {
        color: var(--primaryColor);
        opacity: 0.2; /* Firefox */
      }
    }
  }

  .card {
    width: 623px;
    padding: 10px 40px 40px;
    .CardLogo {
      float: right;
    }
    .nameLetter {
      height: 120px;
      width: 120px;
      background-color: #fff5f6;
      border: 5px solid #fff;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      text-transform: uppercase;
      h2 {
        color: #101828; //mostlyused
        font-size: 30px;
        line-height: 27px;
      }
    }
    .card_details {
      margin-top: 40px;
      h3 {
        color: #101828; //mostlyused
        text-transform: capitalize;
        font-size: 32px;
        line-height: 43px;
      }
    }

    .card_moreInfo {
      display: flex;
      align-items: center;
      justify-content: start;
      gap: 12px;
      flex-direction: row;

      .countryLogo {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 12px;
        flex-direction: row;

        .imgCountryContainer {
          height: 25px;
          width: 25px;
          img {
            border-radius: 100%;
            height: 100%;
            width: 100%;
            height: 26px;
            width: 26px;
            object-fit: cover;
          }
        }
        .countryName {
          color: var(--Gray-600, #475467);
          font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%; /* 14.4px */
        }
      }
      .interest_item {
        background-color: #fff5f6;
        border-radius: 30px;
        width: max-content;
        padding: 7px 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5px;

        .Interest_img_icon {
          height: 16px;
          width: 16px;
        }
        .Interest_text {
          margin: 0;
          font-weight: 300;
          font-size: 12px;
          color: #667085;
          line-height: 14px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .card {
      width: 100%;
    }
    .Name_field {
      position: relative;
      top: 10px;
    }
  }
}

.namecountry {
  color: var(--Gray-600, #475467);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}
.interest_itemaaa {
  display: inline-flex;
  padding: 6px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 30px;
  border: 1px solid #eff0f1;
  background: #fff;
}
.studentat {
  color: var(--Gray-500, #667085);
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 28.8px */
}

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

// #Step7Dk39 {
//   width: 100%;

.stepWrapper {
  text-align: left;
}

.section_title {
  width: 70%;
}

.agreetoour {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.agreetoour span {
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.sentanemail {
  color: var(--Gray-900, #101828);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.sentanemail span {
  color: var(--Primary-600, #e31b54);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}
.otp_wrapper {
  .Seperator {
    width: 40px;
    @media only screen and (max-width: 768px) {
      width: 10px;
    }
  }
  .OTPInput {
    height: 94px;
    width: 80px !important;
    border: none;
    outline: none;
    border-radius: 5px;
    border-left: 2px solid #fff;
    background: rgba(255, 255, 255, 0.6);

    /* Trans Card v2 */
    font-size: 33px;
    @media only screen and (max-width: 768px) {
      height: 80px;
      width: 60px !important;
    }
  }
  .TermsCHck {
    margin-top: 15px;
    display: flex;
    gap: 10px;

    .CheckBoxInput {
      border: none;
      outline: none;
      background-color: #fff;
      border-radius: 5px;
    }
    .CheckBoxInput:checked {
      color: #e51b64;
      height: 59px;
      background-color: #e31b54; /* Change this color to your desired color */
    }
  }
}

@media only screen and (max-width: 992px) {
  .section_title {
    width: 100%;
  }
}
// }
#Step8Dk39 {
  width: 100%;

  .stepWrapper {
    text-align: left;
  }

  .section_title {
    h3 {
      color: #101828; //mostlyused
    }
    width: 70%;
    p {
      font-family: sans-serif;
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 992px) {
    .section_title {
      width: 100%;
    }
  }
}
#StudentRegister127 {
  position: relative;
  .ProgressBarForm {
    position: absolute;
    z-index: 2;
    top: 0;
    height: 5px;
    background-color: var(--primaryColor);
    border-radius: 6px;
    // width: 30%;
  }

  .boxShadow {
    box-shadow: 0px 2.191px 1.753px 0px rgba(0, 49, 122, 0.02),
      0px 5.265px 4.212px 0px rgba(0, 49, 122, 0.03),
      0px 9.913px 7.93px 0px rgba(0, 49, 122, 0.04),
      0px 17.683px 14.146px 0px rgba(0, 49, 122, 0.04),
      0px 33.074px 26.459px 0px rgba(0, 49, 122, 0.05),
      0px 79.167px 63.333px 0px rgba(0, 49, 122, 0.07);
    padding: 0%;
    margin: 0%;
  }

  .backButton {
    cursor: pointer;
    color: #101828; //mostlyused
    position: relative;

    h6 {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      font-family: "Poppins", sans-serif;
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
  .wrapper {
    // background-color: #44b09e;
    // background-image: linear-gradient(315deg, #aaddd5 0%, #e0d2c7 74%);
    // background-image: url(https://varyance.b-cdn.net/uploads/bg.png);
    // background: linear-gradient(to left, #c9daf9 0%, #f9e5ec 80%);
    // background-color: #9ab0d6;
    // background-image: linear-gradient(
    //   203deg,
    //   #9ab0d6 0%,
    //   #fbfbfc 47%,
    //   #f7aabd 100%
    // );
    background: rgb(252, 236, 242);
    background: linear-gradient(
      203deg,
      rgba(225, 232, 246, 1) 0%,
      rgba(252, 247, 248, 1) 50%,
      rgba(252, 236, 242, 1) 100%
    );

    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;

    position: relative;
  }
  .cntentWrapper {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
  .sectionTitle {
    text-align: center;
  }
  .section_title {
    h3 {
      color: #101828; //mostlyused
      text-align: left;
    }
  }
  .choices_wrapper {
    position: relative;
    display: flex;

    flex-direction: column;
  }
  h3 {
    color: var(--primaryColor);
  }
  .action_Btn {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    gap: 40px;

    .Next_Info {
      display: flex;
      justify-content: start;
      align-content: center;
      gap: 20px;
      flex-direction: row;
      color: rgba(227, 27, 84, 0.3);

      font-feature-settings: "clig" off, "liga" off;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      .EnterIcon {
        background: rgba(71, 84, 103, 0.2);
        border-radius: 2px;
        padding: 0 2px;
        color: #fff;
      }
    }
    .cutsPdn {
      width: 160px;
    }
  }
  .margiTop {
    margin-top: 20px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-direction: row;
    color: #98a2b3;
    flex-wrap: wrap;
    p {
      font-family: "Poppins", sans-serif;
    }

    margin: auto auto;

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;
    }
  }
  @media only screen and (max-width: 768px) {
    .choices_wrapper {
      width: 100%;
    }
    .footer {
      width: 100%;
    }
  }
}

.footertextnew {
  color: rgba(12, 17, 29, 0.6);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  transition: color 0.3s;
}
.footertext:hover {
  color: #e31b54; /* Color to be applied on hover */
}

.writtenLogoLinear {
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: var(
    --varyance-red,
    linear-gradient(136deg, #c01048 -4.38%, #a11043 67.26%, #89123e 102.63%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.alreadyacc {
  color: var(--Gray-900, #101828);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 25.6px */
}
.alreadyacc span {
  color: var(--Primary-600, #e31b54);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  cursor: pointer;
}
.fontFamily {
  color: var(--Gray-900, #101828);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
}
.margintop {
  margin-top: 6rem;
}
.Next_Info {
  display: "flex";
  gap: "10px";
  height: "24px";
  width: "126px";
  color: rgba(227, 27, 84, 0.3);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.entericon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 2px;
  background: rgba(192, 16, 72, 0.3);
}
.BackbuttonFONT {
  color: var(--Gray-900, #101828);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
  transition: color 0.3s; /* Adding a smooth transition effect */
}

.BackbuttonFONT:hover {
  color: #e31b54; /* Color to be applied on hover */
}

.AllRightsReserved {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  color: rgba(12, 17, 29, 0.6);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}

.contact {
  max-height: 45px;
  font-size: 1rem;
}

/* Add media queries for different screen sizes */
@media screen and (max-width: 768px) {
  .contact {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 1052px) {
  .contact {
    max-height: 60px;
  }
}

@media screen and (max-width: 576px) {
  .contact {
    font-size: 0.8rem;
  }
}
.blueshadow {
  box-shadow: 0px 2.242px 1.794px 0px rgba(0, 49, 122, 0.02),
    0px 5.389px 4.311px 0px rgba(0, 49, 122, 0.03),
    0px 10.147px 8.118px 0px rgba(0, 49, 122, 0.04),
    0px 18.101px 14.48px 0px rgba(0, 49, 122, 0.04),
    0px 33.855px 27.084px 0px rgba(0, 49, 122, 0.05),
    0px 81.036px 64.829px 0px rgba(0, 49, 122, 0.07);
}
.supportbutton {
  display: flex;

  height: 40px;
  padding: 10.236px 16.378px;
  justify-content: center;
  align-items: center;
  gap: 8.189px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 2.242px 1.794px 0px rgba(0, 49, 122, 0.02),
    0px 5.389px 4.311px 0px rgba(0, 49, 122, 0.03),
    0px 10.147px 8.118px 0px rgba(0, 49, 122, 0.04),
    0px 18.101px 14.48px 0px rgba(0, 49, 122, 0.04),
    0px 33.855px 27.084px 0px rgba(0, 49, 122, 0.05),
    0px 81.036px 64.829px 0px rgba(0, 49, 122, 0.07);
  color: var(--Gray-900, #101828);
  font-family: "Poppins";
  font-size: 16.378px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 26.205px */
}

#Navbar_container {
  position: absolute;
  top: 20px;
  width: 100%;

  .bg-body-tertiary {
    background-color: transparent !important;
    padding-right: 40px;
    padding-left: 40px;
  }
  .nav-link {
    font-weight: 500;
    color: #101828; //mostlyused
  }

  .Logo_nav {
    height: 64px;
    width: 64px;
    margin-top: 51px;
    margin-left: 73px;

    filter: drop-shadow(0px 2.191px 1.753px rgba(0, 49, 122, 0.02))
      drop-shadow(0px 5.265px 4.212px rgba(0, 49, 122, 0.03))
      drop-shadow(0px 9.913px 7.93px rgba(0, 49, 122, 0.04))
      drop-shadow(0px 17.683px 14.146px rgba(0, 49, 122, 0.04));
  }
  .Nav_list_wrapper {
    gap: 30px;
  }
  .nav-item {
    margin: auto auto;
    display: flex;
    justify-content: center;
    align-content: center !important;
  }
  .Login_btn {
    border: none;
    border-radius: 5px;
    background-color: #fff;
    padding: 10.5px 12.5px;
    width: 145px;
    height: 45px;
    font-weight: 500;
    color: #101828; //mostlyused
    box-shadow: 0px 2.242483139038086px 1.793986439704895px 0px #6c49ac05;

    box-shadow: 0px 5.388998508453369px 4.311198711395264px 0px #6c49ac07;

    box-shadow: 0px 10.147006034851074px 8.117605209350586px 0px #6c49ac09;

    box-shadow: 0px 18.100526809692383px 14.480419158935547px 0px #6c49ac0b;

    box-shadow: 0px 33.855045318603516px 27.084033966064453px 0px #6c49ac0d;

    box-shadow: 0px 81.03633117675781px 64.82906341552734px 0px #6c49ac12;
  }

  @media only screen and (max-width: 992px) {
    .bg-body-tertiary {
      // background-color: rgb(228, 228, 228) !important;
      padding-right: 10px;
      padding-left: 10px;
    }
    .Navbar_container {
      top: 10px;
    }
    .Nav_list_wrapper {
      background: rgba(207, 207, 207, 0.13);
      border-radius: 16px;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(6.3px);
      -webkit-backdrop-filter: blur(6.3px);
      border: 1px solid rgba(207, 207, 207, 0.3);
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }
}
